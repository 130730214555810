import { userService } from '@/services/userService.js';
import router from '@/router';
import jwt_decode from "jwt-decode";
import track from '@/helpers/track.js';

const user = JSON.parse(localStorage.getItem('user'));
let reauthTimer = null;

const state = { 
	// The status of whether the user is logged in or not.
    status: {
        loggedIn: user && user.expiry > (Date.now() / 1000) ? true : false,
    }, 
	// The current logged in user (if any)
	user: user && user.expiry > (Date.now() / 1000) ? user : { 
        firstName: null,
        lastName: null,
        email: null,
        token: null,
		expiry: null,
		issueDate: null, // The date that the JWT was issued (to calculate the total length that the JWT is/was valid for)
    },
	// Used for register process inputs
	newUser: { 
		firstName: null,
		lastName: null,
		password: null,
		passwordReenter: null,
		email: null,
	},
	// Used for Login process inputs
	loginUser: { 
		email: null,
		password: null,
	},
	// User Profile data
	userProfile: null,
};

const getters = { 
	isLoggedIn(state) {
		return state.status && state.status.loggedIn;
	},
	getAuthHeader(state) {
		if(state.user && state.user.token) {
			return { Authorization: `Bearer ${state.user.token}` };
		}
		return "";
	},
	getAuthToken(state) {
		if(state.user.token) {
			return state.user.token;
		}
		return "";
	},
	getUserAuthExpiry(state) {
		if(state.user.expiry) {
			return state.user.expiry;
		}
		return false;
	},
	getUserAuthIssueDate(state) {
		if(state.user.issueDate) {
			return state.user.issueDate;
		}
		return false;
	}
}

let handleToken = (token) => {
	var tokenData = jwt_decode(token)[0];
	let user = {
		token: token,
		email: tokenData.email,
		// // This name data is removed from the local data to prevent outdated name data being used after the user updates their profile data (before a new JWT is issued). It is still being provided by the backend JWT though, just not used.
		// firstName: tokenData.firstname, 
		// lastName: tokenData.lastname,
		expiry: tokenData.expiry,
		issueDate: Date.now() / 1000,
	}
	// Save user in local storage. Temporary : TODO is this secure? Can this be done better? Research. See bitbucket issue.
	localStorage.setItem('user', JSON.stringify(user));

	return user;
}

const actions = {
	initAccount({ dispatch, getters }) {
		dispatch('reauthenticate') // Reauthenticate the user immediately 
		dispatch('autoReauthenticate') // Reauthenticate the user every x before their token expires (if they are still using the app)
	},
    login({ dispatch, commit }, { email, password, rememberMe = false }) {
        let data = {
            username: email,
			password: password,
			remember_me: rememberMe,
        }
        commit('loginRequest', { email });
        return userService.login(data)
			.then((response) => {
				let user = handleToken(response.data.data.token);
				commit('loginSuccess', user);
				dispatch('basket/initBasket', "Logged in", { root:true }) // Refresh stored basket data for logged in user
				dispatch('autoReauthenticate') // Reauthenticate the user every x before their token expires (if they are still using the app)
				try {
					track.event('login', {
						method: 'Password'
					});
				} catch(e) {
					console.error(e);
					this.$rollbar.error("Tracking: " + e);
				}
				return true;
			}, error => {
				commit('loginFailure');
				dispatch('alert/error', error, { root: true });
				return false;
			});
	},
	autoReauthenticate({dispatch, commit, getters}) { // Automatically reauthenticate the logged in user every x minutes.
		reauthTimer = setInterval(() => {
			dispatch('reauthenticate') // Check if reauth needed, and reauth if so.
		}, 60000) // Repeat every 60 seconds 
	},
	reauthenticate({dispatch, commit, getters}) {
		if(getters.isLoggedIn) {
			let loginExpiry = getters.getUserAuthExpiry;
			if(loginExpiry) {
				// Only reauth if nearing the end of the JWT expiry
				let loginIssued = getters.getUserAuthIssueDate;
				let totalLength = loginExpiry - loginIssued;
				let now = Date.now() / 1000;
				let shouldReauth = now > loginExpiry - (totalLength / 2) // If we are in the 2nd half of the JWT length (e.g. final 5 mins for short login, final 2 weeks for "remember me" login)
				let token = getters.getAuthToken;
				let data = {};
				if(shouldReauth) { 
					// We only want to send data if they are nearing expiry (should reauth). If not, we still send the reauth request to tell the backend we are still active, but we don't expect a new token in return.
					data = {
						jwt: token,
					};
				}
				if(token != "" && token != null) {
					return userService.reauthenticate(data)
						.then(response => {
							if(response.data.data && response.data.data.token) { // Don't handle this if there is no token returned (i.e. if we are not nearing expiry)
								let user = handleToken(response.data.data.token);
								commit('loginSuccess', user);
							}
							return true;
						}),
						error => {
							commit('loginFailure');
							dispatch('alert/error', error, { root: true });
							return false;
						};
				} else {
					return false;
				}
			}
			
		} else {
			// console.log("User not logged in, not reauthenticating...");
		}
	},
	googleLogin({ dispatch, commit }, { googleToken }) {
		let data = {
			token: googleToken,
			remember_me: true, // Always count a google login as a long login (if not, design changes needed)
		};
		return userService.googleLogin(data)
			.then((response) => {
				let user = handleToken(response.data.data.token);
				commit('loginSuccess', user);
				dispatch('basket/initBasket', "Logged in", { root:true }) // Refresh stored basket data for logged in user
				dispatch('autoReauthenticate') // Reauthenticate the user every x before their token expires (if they are still using the app)
				try { 
					track.event('login', {
						method: 'Google'
					});
				} catch(e) {
					console.error(e);
					this.$rollbar.error("Tracking: " + e);
				}
				
				return true;
			},
			error => {
				commit('loginFailure');
				dispatch('alert/error', error, { root: true });
				return false;
			});

	},
	facebookLogin({ dispatch, commit }, { email, facebookToken }) {
		let data = { email: email, facebookToken: facebookToken };
		commit('loginRequest', { email });
		return userService.facebookLogin(data)
			.then((response) => {
				let user = handleToken(response.data.data.token);
				commit('loginSuccess', user);
				dispatch('basket/initBasket', "Logged in", { root:true }) // Refresh stored basket data for logged in user
				dispatch('autoReauthenticate') // Reauthenticate the user every x before their token expires (if they are still using the app)
				try {
					track.event('login', {
						method: 'Facebook'
					});
				} catch(e) {
					console.error(e);
					this.$rollbar.error("Tracking: " + e);
				}
				
				return true;
			},error => {
				commit('loginFailure');
				dispatch('alert/error', error, { root: true });
				return false;
			});
	},
    logout({ commit }) {
        userService.logout();
        commit('logout');
		try {
			track.event('logout', {});
		} catch(e) {
			console.error(e);
			this.$rollbar.error("Tracking: " + e);
		}
    },
    async register({ dispatch, commit }, user) {
        let data = {
			name: {
				firstname: user.firstName,
				lastname: user.lastName,
			},           
            password: user.password,
			email: user.email,
			password_confirm: user.passwordReenter
        }
        commit('registerRequest', data);
        return userService.register(data)
            .then(
                user => {
					commit('registerSuccess', user);
					dispatch('account/login', { email: data.email, password: data.password }, { root: true })
					return true;
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('alert/error', error, { root: true });
					return false;
                }
            );
	},
	async checkEmail({ dispatch, commit }, { email }) {
		return userService.checkEmail(email)
			.then(
				response => {
					return response;
				},
				error => {
					return Promise.reject(error);
				}
			);
	},
	resetPassword({ dispatch, commit }, { email }) {
		let data = {
			email: email,
		}
		return userService.resetPassword(data)
			.then(
				response => {
					return response;
				},
				error => {
					return Promise.reject(error);
				}
			);
	},
	updatePassword({ dispatch, commit }, { password, passwordReenter, uniqueCode }) {
		let data = {
			key: uniqueCode,
			password: password,
			password_confirm: passwordReenter,
		}
		return userService.updatePassword(data)
			.then(
				response => {
					return response;
				},
				error => {
					return Promise.reject(error);
				}
			);
	},

	async fetchUserProfile({ commit }) {
		return userService.fetchUserProfile()
			.then(
				response => {
					commit('SET_USER_PROFILE', response.data.data);
					return response;
				},
				error => {
					return Promise.reject(error);
				}
			);
	},

	async saveUserProfile({ commit }, pax_data) {
		return userService.saveUserProfile(pax_data)
			.then(async response => {
				commit('SET_USER_PROFILE', response.data.data);
				return response;
			},
			error => {
				return Promise.reject(error);
			});
	}
};

const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
    },
    loginSuccess(state, user) {
        state.status = { loggedIn: true };

        //Get the data from the JWT, store in user state
        state.user = user;
    },
    loginFailure(state) {
        state.status = null;
        state.user = null;
    },
    logout(state) {
        state.status = null;
        state.user = null;
		state.userProfile = null;
    },
    registerRequest(state, user) {
        state.status = { registering: true };
    },
    registerSuccess(state, user) {
        state.status = null;
    },
    registerFailure(state, error) {
        state.status = null;
    },
	SET_USER_PROFILE(state, response) {
		state.userProfile = {};
		state.userProfile.paxFirstName = response.firstname ? response.firstname : "";
		state.userProfile.paxLastName = response.lastname ? response.lastname : "";
		state.userProfile.paxGender = response.gender ? response.gender : "";
		state.userProfile.paxEmail = response.email ? response.email : "";
		
		let number = response.phone && JSON.parse(response.phone) ? JSON.parse(response.phone).fullNumber : false;
		state.userProfile.paxPhone = number ? number : "";
		state.userProfile.paxDob = response.dob ? response.dob : "";
		state.userProfile.paxPassportNumber = response.passport_number ? response.passport_number : "";
		state.userProfile.paxCountryOfIssue = response.country_of_issue ? response.country_of_issue : "";
		state.userProfile.paxPassportExpiry = response.passport_expiry ? response.passport_expiry : "";
	
		state.userProfile.coverImage = response.images && response.images.banner ? response.images.banner : false;
		state.userProfile.profileImage = response.images && response.images.profile ? response.images.profile : false;
	}
};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations,
	getters
};