import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'

// Error handling
import Rollbar from 'rollbar';

// Don't use Rollbar in development mode
if (process.env.NODE_ENV === 'development') {
  const Rollbar = function () {
    // I only call $rollbar.error in the app
    this.error = console.error
    // define other rollbar API mocks here
  }
  const RollbarMockPlugin = {
    install: function (Vue, options) {
      const rollbarInstance = new Rollbar(options);
      Vue.rollbar = rollbarInstance;
      Vue.prototype.$rollbar = rollbarInstance;
    }
  }
  Vue.use(RollbarMockPlugin)
} else {
  // Normal Rollbar configuration
  Vue.prototype.$rollbar = new Rollbar({
    accessToken: '21f39bf77b474bba900a30b24fa78e0b',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      code_version: '1.0.0',
    }
  });
}

Vue.config.errorHandler = (err, vm, info) => {
	console.error(err);
	vm.$rollbar.error("Error caught by Vue errorHandler. " + err);
	throw err; // rethrow
};

// Plugins
import VCalendar from 'v-calendar';
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
	componentPrefix: 'v',  // Use <vc-calendar /> instead of <v-calendar />
});

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css'; // Style optional - don't import to write ourselves
// 
Vue.component('v-select', vSelect)

// // https://github.com/surmon-china/vue-awesome-swiper
// import SwiperClass, { Pagination, Navigation } from 'swiper'
// import VueAwesomeSwiper from 'vue-awesome-swiper' // This is a wrapper for https://swiperjs.com/vue that works with Vue2. If we migrate to Vue3 can be replaced with out of the box version (that only works with Vue3)
// import 'swiper/swiper-bundle.css'
// SwiperClass.use([Pagination, Navigation])
// Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

import Meta from "vue-meta";
Vue.use(Meta);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

import VueToast from 'vue-toast-notification';
// Import one of the available themes
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast, {
	position: 'top',
	duration: 5000,
	dismissible: true,
});

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
Vue.use(VueTelInput);

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: function (h) { return h(App) }
}).$mount('#app')