import Vue from 'vue';
import VueRouter from 'vue-router';
import { store } from '../store/index';

Vue.use(VueRouter)

const routes = [
	{
		path: '/example',
		name: 'Example',
		component: () => import('../views/Example.vue'),
	},
	{
		path: '/',
		name: 'Home',
		component: () => import('../views/Home.vue'),
	},
	{
		path: '/example',
		name: 'Example',
		component: () => import('../views/Example.vue'),
	},
	{
		path: '/newsletter-signup',
		name: 'Newsletter Signup',
		component: () => import('../views/NewsletterSignup.vue'),
	},
	{
		path: '/newsletter-signup/success',
		name: 'Newsletter Signup Success',
		component: () => import('../views/SuccessOrFail.vue'),
		props: {
			success: true,
			title: "Success!",
			mainText: "You have signed up to our newsletter.",
			actionButtonRoute: {
				name: 'Home'
			}
		}
	},
	/* Dynamic pages from CMS. Fetches data with the provided ID. ID can be the CMS ID (e.g. 43) or the defined URL in the CMS (e.g. our-mission) */
	{
		path: '/page/:id',
		name: 'CMS Page',
		component: () => import('../views/CMSPage.vue'),
	},
		/* Hardcoded pages from CMS. Fetches data with the provided ID as prop, allowing us to define a specific URL */
	{
		path: '/our-mission',
		name: 'Mission',
		component: () => import('../views/CMSPage.vue'),
		props: {
			pageIDProp: "our-mission"
		}
	},
	{
		path: '/privacy-policy',
		name: 'Privacy Policy',
		component: () => import('../views/CMSPage.vue'),
		props: {
			pageIDProp: "privacy-policy",
			showBlogs: false,
		}
	},
	{
		path: '/terms-and-conditions',
		name: 'Terms and Conditions',
		component: () => import('../views/CMSPage.vue'),
		props: {
			pageIDProp: "terms-and-conditions",
			showBlogs: false,
		}
	},
	{
		path: '/licences',
		name: 'Licences',
		component: () => import('../views/CMSPage.vue'),
		props: {
			pageIDProp: "licences",
			showBlogs: false,
		}
	},
	{
		path: '/community',
		name: 'Community Stories',
		component: () => import('../views/blogs/BlogList.vue'),
	},
	{
		path: '/community/:id',
		name: 'Blog Article',
		component: () => import('../views/blogs/BlogArticle.vue'),
		props: true
	},
	{
		path: '/country/:country_code',
		name: 'Country',
		component: () => import('../views/Country.vue'),
		props: true
	},
	{
		path: '/my-trips',
		name: 'My Trips',
		component: () => import('../views/trips/Trips.vue'),
	},
	{
		path: '/trip/:trip_id',
		name: 'Trip',
		component: () => import('../views/trips/Trip.vue'),
		meta: { transitionName: 'slide', order: 70 },
	},
	{
		path: '/delete-trip/:trip_id',
		name: 'Delete Trip',
		component: () => import('../views/trips/DeleteTrip.vue'),
		// meta: { transitionName: 'slide', order: 80 },
	},
	// {
	// 	path: '/profile',
	// 	name: 'Profile',
	// 	component: () => import('../views/profile/Profile.vue'),
	// },
	{ // Temp redirect profile to profile details, until we have dedicated profile page
		path: '/profile',
		name: 'Profile',
		component: () => import('../views/profile/DetailsForm.vue'),
	},
	{
		path: '/profile-details',
		name: 'Profile Details',
		component: () => import('../views/profile/DetailsForm.vue'),
	},
	{
		path: '/friends',
		name: 'Friends',
		component: () => import('../views/profile/Friends.vue'),
	},
	// Flights search screens
	{
		path: '/flights-locations',
		name: 'Flights Locations',
		component: () => import('../views/search/flights/FlightsSearchLocations.vue'),
		meta: { 
			transitionName: 'slide', 
			order: 10, 
			keepQueryParams: true, // Keep any query params from the previous page
		},
	},
	{
		path: '/flights-dates',
		name: 'Flights Dates',
		component: () => import('../views/search/flights/FlightsSearchDates.vue'),
		meta: { transitionName: 'slide', order: 20, keepQueryParams: true, },
	},
	{
		path: '/flights-summary',
		name: 'Flights Search',
		component: () => import('../views/search/flights/FlightsSearchOverview.vue'),
		meta: { transitionName: 'slide', order: 30, keepQueryParams: true, },
	},
	{
		path: '/flights-results',
		name: 'Flights Results',
		component: () => import('../views/search/flights/FlightsSearchResults.vue'),
		meta: { transitionName: 'slide', order: 40, keepQueryParams: true, },
	},
	{
		path: '/flights-results/filters',
		name: 'Flights Filters',
		component: () => import('../views/search/flights/filters/FlightsFiltersOverview.vue'),
		meta: { transitionName: 'slide', order: 50, keepQueryParams: true, },
	},
	{
		path: '/flights-results/filters/stops',
		name: 'Flights Filters Stops',
		component: () => import('../views/search/flights/filters/FlightsFiltersStops.vue'),
		meta: { transitionName: 'slide', order: 60, keepQueryParams: true, },
	},
	{
		path: '/flights-results/filters/airlines',
		name: 'Flights Filters Airlines',
		component: () => import('../views/search/flights/filters/FlightsFiltersAirlines.vue'),
		meta: { transitionName: 'slide', order: 60, keepQueryParams: true, },
	},
	{
		path: '/flights-results/filters/price',
		name: 'Flights Filters Price',
		component: () => import('../views/search/flights/filters/FlightsFiltersPrice.vue'),
		meta: { transitionName: 'slide', order: 60, keepQueryParams: true, },
	},
	{
		path: '/flights-results/filters/duration',
		name: 'Flights Filters Duration',
		component: () => import('../views/search/flights/filters/FlightsFiltersDuration.vue'),
		meta: { transitionName: 'slide', order: 60, keepQueryParams: true, },
	},
	{
		path: '/flights-results/filters/times',
		name: 'Flights Filters Times',
		component: () => import('../views/search/flights/filters/FlightsFiltersTimes.vue'),
		meta: { transitionName: 'slide', order: 60, keepQueryParams: true, },
	},
	{
		path: '/flights-details/:outbound_key/:return_key?',
		name: 'Flights Details',
		component: () => import('../views/search/flights/FlightsDetails.vue'),
		props: true,
		meta: { 
			transitionName: 'slide', 
			order: 50, 
			keepQueryParams: true, 
		},
	},
	{
		path: '/select-trip/flights-details/:outbound_key/:return_key?',
		name: 'Flights Select Trip',
		component: () => import('../views/search/flights/FlightsSelectTrip.vue'),
		props: true,
		meta: { transitionName: 'slide', order: 60, keepQueryParams: true, },
	},
	{
		path: '/saved-flights-details/:trip_id/:trip_item_id',
		name: 'Saved Flights Details',
		component: () => import('../views/trips/flights/SavedFlightsDetails.vue'),
		props: true,
		meta: { transitionName: 'slide', order: 80 },
	},
	{
		path: '/confirm-flight-change/:trip_id/:trip_item_id',
		name: 'Confirm Flight Price',
		component: () => import('../views/trips/flights/ConfirmFlightPrice.vue'),
		props: {
			heading: "The ticket price changed. Continue with the new price?",
		},
		meta: { transitionName: 'slide', order: 90 },
	},
	
	// Tours search screens
	{
		path: '/tours-keyword',
		name: 'Tours Keyword',
		component: () => import('../views/search/tours/ToursSearchKeyword.vue'),
	},
	{
		path: '/tours-dates',
		name: 'Tours Dates',
		component: () => import('../views/search/tours/ToursSearchDates.vue'),
	},
	{
		path: '/tours-summary',
		name: 'Tours Search',
		component: () => import('../views/search/tours/ToursSearchOverview.vue'),
	},
	{
		path: '/tours-results',
		name: 'Tours Results',
		component: () => import('../views/search/tours/ToursSearchResults.vue'),
		meta: { transitionName: 'slide', order: 40, keepQueryParams: true, },
	},
	{
		path: '/tours-results/filters',
		name: 'Tours Filters',
		component: () => import('../views/search/tours/filters/TourFiltersOverview.vue'),
		meta: { transitionName: 'slide', order: 50, keepQueryParams: true, },
	},
	{
		path: '/tours-results/filters/price',
		name: 'Tours Filters Price',
		component: () => import('../views/search/tours/filters/TourFiltersPrice.vue'),
		meta: { transitionName: 'slide', order: 60, keepQueryParams: true, },
	},
	{
		path: '/tours-results/filters/duration',
		name: 'Tours Filters Duration',
		component: () => import('../views/search/tours/filters/TourFiltersDuration.vue'),
		meta: { transitionName: 'slide', order: 60, keepQueryParams: true, },
	},
	{
		path: '/tours-results/filters/locations',
		name: 'Tours Filters Locations',
		component: () => import('../views/search/tours/filters/TourFiltersLocations.vue'),
		meta: { transitionName: 'slide', order: 60, keepQueryParams: true, },
	},
	{
		path: '/tour-details/:tour_id',
		name: 'Tour Details',
		component: () => import('../views/search/tours/TourDetails.vue'),
		props: {
			saved: false,
			fullDetails: true,
		},
	},
	{
		path: '/select-trip/tour-details/:tour_id',
		name: 'Tours Select Trip',
		component: () => import('../views/search/tours/ToursSelectTrip.vue'),
		props: true,
		meta: { transitionName: 'slide', order: 60, keepQueryParams: true, },
	},
	// Version of the tour page that doesn't show the full itinerary, just a tour card.
	{
		path: '/saved-tour-details/:trip_id/:trip_item_id',
		name: 'Saved Tour Details',
		component: () => import('../views/search/tours/TourDetails.vue'),
		props: {
			saved: true,
			fullDetails: false,
		},
		meta: { transitionName: 'slide', order: 80 },
	},
	{
		path: '/saved-tour-details/full/:trip_id/:trip_item_id',
		name: 'Saved Tour Details Full',
		component: () => import('../views/search/tours/TourDetails.vue'),
		props: {
			saved: true,
			fullDetails: true,
		},
		meta: { transitionName: 'slide', order: 90 },
	},
	

	// New - Travellers
	/*
	 * Handles adding a new traveller. This can be to the friends list (no params), or to a trip / trip item (with props)
	 */
	{
		path: '/new-traveller/:trip_id?/:trip_item_id?',
		name: 'Add Traveller',
		component: () => import('../views/trips/travellers/TravellerForm.vue'),
		props: true,
		meta: { transitionName: 'slide', order: 100 },
	},
	/*
	 * Handles editing a traveller. This can be to the friends list (no params), or to a trip / trip item (with props)
	 */
	{
		path: '/edit-traveller/:traveller_id/:trip_id?/:trip_item_id?',
		name: 'Edit Traveller',
		component: () => import('../views/trips/travellers/TravellerForm.vue'),
		props: true,
		meta: { transitionName: 'slide', order: 100 },
	},

	/*
	 * Allows management of travellers on a trip item. Redirects the user back to the trip item details screen when done.
	 */
	{
		path: '/trip-item-travellers/:trip_id/:trip_item_id',
		name: 'Manage Trip Item Travellers',
		component: () => import('../views/trips/travellers/ManageTripItemTravellers.vue'),
		props: {
			flow: 'trip-item',
		},
		meta: { transitionName: 'slide', order: 90 },
	},
	/*
	 * Allows management of travellers on a trip item, as part of the checkout process. Usually triggered by a "book" button, but the items(s) to book have traveller issues. The user is taken to this screen to edit the traveller(s) and then continue through to the booking review screen.
	 */
	{
		path: '/checkout/trip-item-travellers/:trip_id/:trip_item_id',
		name: 'Checkout Manage Trip Item Travellers',
		component: () => import('../views/trips/travellers/ManageTripItemTravellers.vue'),
		props: {
			flow: 'checkout',
		},
		meta: { transitionName: 'slide', order: 90 },
	},
	/*
	 * Allows management of travellers on a after the initial "add to trip" process. Does not allow the user to go "back" once loaded on this page, as this would break the flow (the user would be taken back to the trip item details screen, which would then let them add the item a second time). Instead redirects the user to the new saved trip item.
	 */
	{
		path: '/add/trip-item-travellers/:trip_id/:trip_item_id',
		name: 'Add Manage Trip Item Travellers',
		component: () => import('../views/trips/travellers/ManageTripItemTravellers.vue'),
		props: {
			flow: 'add',
		},
		meta: { transitionName: 'slide', order: 90 },
	},
	/*
	 * Allows management of travellers on a trip item, when navigated from a Trip. The user is taken to this screen to edit the traveller(s) and then continue through back to the Trip page.
	 */
	{
		path: '/trip/trip-item-travellers/:trip_id/:trip_item_id',
		name: 'Trip Manage Trip Item Travellers',
		component: () => import('../views/trips/travellers/ManageTripItemTravellers.vue'),
		props: {
			flow: 'trip',
		},
		meta: { transitionName: 'slide', order: 90 },
	},
	
	/*
	 * Allows management of travellers on a trip
	 */
	{
		path: '/trip-travellers/:trip_id',
		name: 'Manage Trip Travellers',
		component: () => import('../views/trips/travellers/ManageTripTravellers.vue'),
		props: true,
		meta: { transitionName: 'slide', order: 80 },
	},
		

	// Old - passengers
	// {
	// 	path: '/passengers/:trip_id/:trip_item_id?',
	// 	name: 'Passenger Details',
	// 	component: () => import('../views/trips/passengers/OverviewPassengers.vue'),
	// 	props: true
	// },
	// {
	// 	path: '/select-passenger/:trip_id/:trip_item_id?',
	// 	name: 'Select Passenger',
	// 	component: () => import('../views/trips/passengers/SelectPassenger.vue'),
	// 	props: true
	// },
	// {
	// 	path: '/add-passenger/:trip_id/:trip_item_id?',
	// 	name: 'Add Passenger',
	// 	component: () => import('../views/trips/passengers/Passenger.vue'),
	// 	props: true
	// },
	// {
	// 	path: '/edit-passenger/:trip_id/:trip_item_id?/:pax_id',
	// 	name: 'Edit Passenger',
	// 	component: () => import('../views/trips/passengers/Passenger.vue'),
	// 	props: true
	// },
	{
		path: '/remove/:trip_id/:trip_item_id',
		name: 'Remove Trip Item',
		component: () => import('../views/trips/RemoveTripItem.vue'),
		props: true,
		meta: { transitionName: 'slide', order: 90 },

	},
	{
		path: '/checkout/review/:trip_id/:trip_item_id?',
		name: 'Review',
		component: () => import('../views/trips/checkout/Review.vue'),
		props: true
	},
	{
		path: '/checkout/payment/:trip_id/:trip_item_id?',
		name: 'Payment',
		component: () => import('../views/trips/checkout/Payment.vue'),
		props: true
	},
	{
		path: '/checkout/confirmation/:trip_id/:trip_item_id?',
		name: 'Payment Confirmation',
		component: () => import('../views/trips/checkout/Confirmation.vue'),
		props: true
	},
	{
		path: '/hotels',
		name: 'Hotels',
		component: () => import('../views/Home.vue'),
	},
	{
		path: '/car-hire',
		name: 'Car Hire',
		component: () => import('../views/Home.vue'),
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/auth/Login.vue'),
	},
	{
		path: '/login-password',
		name: 'Login Password',
		component: () => import('../views/auth/LoginPassword.vue'),
	},
	{
		path: '/reset-password',
		name: 'Reset Password',
		component: () => import('../views/auth/ResetPassword.vue'),
	},
	{
		path: '/reset-sent',
		name: 'Reset Sent',
		component: () => import('../views/SuccessOrFail.vue'),
		props: {
			success: true,
			title: "Success!",
			mainText: "Reset password link has been sent to your email address. Please check your inbox.",
			actionButtonRoute: {
				name: 'Login'
			}
		}
	},
	{
		path: '/reset-password/:code',
		name: 'Update Password',
		component: () => import('../views/auth/UpdatePassword.vue'),
	},
	{
		path: '/update-fail',
		name: 'Update Password Fail',
		component: () => import('../views/SuccessOrFail.vue'),
		props: {
			success: false,
			title: "Oops!",
			mainText: "Something went wrong. The password reset link may have expired. Try again below.",
			actionButtonRoute: {
				name: 'Reset Password'
			},
			actionButtonText: "Try Again"
		}
	},
	{
		path: '/update-success',
		name: 'Update Password Success',
		component: () => import('../views/SuccessOrFail.vue'),
		props: {
			success: true,
			title: "Password has changed",
			mainText: "Your password has been updated. You can log in with your new password.",
			actionButtonRoute: {
				name: 'Login'
			},
			actionButtonText: "Sign in"
		}
	},
	{
		path: '/register',
		name: 'Register',
		component: () => import('../views/auth/Register.vue'),
	},
	{
		path: '/register-email',
		name: 'Register Email',
		component: () => import('../views/auth/RegisterEmail.vue'),
	}, 
	{
		path: '/register-password',
		name: 'Register Password',
		component: () => import('../views/auth/RegisterPassword.vue'),
	}, 
	{
		path: '/register-success',
		name: 'Register Success',
		component: () => import('../views/SuccessOrFail.vue'),
		props: {
			success: true,
			title: "You're all set!",
			mainText: "Welcome to the Community! Now let’s change the world together.",
			actionButtonRoute: {
				name: 'Profile'
			},
			actionButtonText: "Set up profile"
		}
	},

	// If no route matched, redirect to home
	{ 
		path: '*', 
		name: '404',
		component: () => import('../views/404.vue'),
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) { // If the user pressed the back button
			return savedPosition; // Scroll to where they were
		} else if (to.hash) { // If there is a hash
			return { // Scroll to hash
				selector: to.hash
			};
		} else { // Else scroll to top
			return { y: 0, behavior: 'smooth', };
		}
	},
})

router.beforeEach((to, from, next) => {
	// redirect to login page if not logged in and trying to access a restricted page
	let privatePages = ['/profile', '/my-trips', '/profile-details'];
	let authRequired = privatePages.includes(to.path);
	let loggedIn = store.getters['account/isLoggedIn']

	if (authRequired && !loggedIn) {
		router.push({
			name: 'Login',
			query: {
				redirect: to.path,
			}
		});
	}

	if(to.meta.keepQueryParams) { // If the next route wants to keep the query params
		if(!hasQueryParams(to) && hasQueryParams(from)){ // and there are query params to keep (and none already set)
			// Can't use next(to) here because it will push an error to console about redirecting. This error should actually be a warning, but view is annoying, and it messes with our error reporting.
			router.push({ name: to.name, params: to.params, query: from.query }); // Add queries to route
			// next({ ...to, query: from.query }); // Add queries to route
		} else {
			next()
		}
	} else {
		next()
	}
})

function hasQueryParams(route) {
	return !!Object.keys(route.query).length
}

export default router
