<template>
	<div class="inner-wrapper">
		<div v-if="error && !loading" class="icon">
			<div>{{ error }}</div>
			<button class="button small" @click="$emit('refresh')">Refresh</button>
		</div>

		<div class="loading" v-if="loading && type == 'green'">
			<svg version="1.1" id="L1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
				<circle fill="none" stroke="#06D6A0" stroke-width="1" stroke-miterlimit="15" stroke-dasharray="14.2472,14.2472" cx="50" cy="50" r="47">
					<animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="5s" from="0 50 50" to="360 50 50" repeatCount="indefinite"></animateTransform>
				</circle>
				<circle fill="none" stroke="#06D6A0" stroke-width="1" stroke-miterlimit="10" stroke-dasharray="10,10" cx="50" cy="50" r="39">
					<animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="5s" from="0 50 50" to="-360 50 50" repeatCount="indefinite"></animateTransform>
				</circle>
				<g class="ldl-scale" style="transform-origin: 38% 50%; transform: rotate(0deg) scale(0.2, 0.2);"><g class="ldl-ani" style="transform-origin: 108.51px 52.97px; transform: rotate(0deg); animation: 1.40845s linear 0s infinite normal forwards running spin-d3463830-e27c-4de4-b8ed-75bbfc200149;"><g class="ldl-layer"><g class="ldl-ani"><g id="Layer_1-2"><g class="ldl-layer"><g class="ldl-ani"><path d="M164.05,0c-15.24,0-28.97,6.43-38.63,16.73l-16.1,16.51,.06,.06-36.81,37.75-1.08,1.11-.06-.06c-4.78,4.62-11.29,7.46-18.47,7.46-14.68,0-26.59-11.9-26.59-26.59s11.9-26.59,26.59-26.59c6.13,0,11.77,2.07,16.27,5.56l-8.23,8.5h.39l37.98,.76-1.37-18.99-1.34-18.58-9.02,9.31C78.36,4.88,66.23,0,52.97,0,23.72,0,0,23.72,0,52.97s23.72,52.97,52.97,52.97c15.02,0,28.57-6.25,38.21-16.29l-.04-.04,.23-.24,53.1-54.46,.35-.36,.03,.03c4.84-5.06,11.66-8.2,19.21-8.2,14.68,0,26.59,11.9,26.59,26.59s-11.9,26.59-26.59,26.59c-7.3,0-13.91-2.94-18.71-7.7h0l-16.17-15.76-18.24,18.7,12.62,12.3c9.72,11.52,24.26,18.83,40.5,18.83,29.25,0,52.97-23.72,52.97-52.97S193.31,0,164.05,0Z" class="cls-1" style="fill: #06D6A0;"></path></g></g></g></g></g></g></g>
			</svg>
		</div>

		<div class="loading" v-if="loading && type == 'white'">
			<svg version="1.1" id="L1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
				<circle fill="none" stroke="#fff" stroke-width="1" stroke-miterlimit="15" stroke-dasharray="14.2472,14.2472" cx="50" cy="50" r="47">
					<animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="5s" from="0 50 50" to="360 50 50" repeatCount="indefinite"></animateTransform>
				</circle>
				<circle fill="none" stroke="#fff" stroke-width="1" stroke-miterlimit="10" stroke-dasharray="10,10" cx="50" cy="50" r="39">
					<animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="5s" from="0 50 50" to="-360 50 50" repeatCount="indefinite"></animateTransform>
				</circle>
				<g class="ldl-scale" style="transform-origin: 38% 50%; transform: rotate(0deg) scale(0.2, 0.2);"><g class="ldl-ani" style="transform-origin: 108.51px 52.97px; transform: rotate(0deg); animation: 1.40845s linear 0s infinite normal forwards running spin-d3463830-e27c-4de4-b8ed-75bbfc200149;"><g class="ldl-layer"><g class="ldl-ani"><g id="Layer_1-2"><g class="ldl-layer"><g class="ldl-ani"><path d="M164.05,0c-15.24,0-28.97,6.43-38.63,16.73l-16.1,16.51,.06,.06-36.81,37.75-1.08,1.11-.06-.06c-4.78,4.62-11.29,7.46-18.47,7.46-14.68,0-26.59-11.9-26.59-26.59s11.9-26.59,26.59-26.59c6.13,0,11.77,2.07,16.27,5.56l-8.23,8.5h.39l37.98,.76-1.37-18.99-1.34-18.58-9.02,9.31C78.36,4.88,66.23,0,52.97,0,23.72,0,0,23.72,0,52.97s23.72,52.97,52.97,52.97c15.02,0,28.57-6.25,38.21-16.29l-.04-.04,.23-.24,53.1-54.46,.35-.36,.03,.03c4.84-5.06,11.66-8.2,19.21-8.2,14.68,0,26.59,11.9,26.59,26.59s-11.9,26.59-26.59,26.59c-7.3,0-13.91-2.94-18.71-7.7h0l-16.17-15.76-18.24,18.7,12.62,12.3c9.72,11.52,24.26,18.83,40.5,18.83,29.25,0,52.97-23.72,52.97-52.97S193.31,0,164.05,0Z" class="cls-1" style="fill: rgb(255 255 255);"></path></g></g></g></g></g></g></g>
			</svg>
		</div>
	</div>
</template>

<script>
import ResultIcon from '@/components/ResultIcon.vue'
import { mapState } from 'vuex';
export default {
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		error: {
			default: false,
		},
		type: {
			type: String,
			default: "green", // Other option is white
		}
	},
	computed: {
		...mapState({
			alert: state => state.alert,
		}),
	},
	components: {
		ResultIcon,
	},
	methods: {
		home() {
			
		},
		refresh() {

		}
	},
}
</script>

<style scoped>
	.inner-wrapper {
		text-align: center;
	}
	.button {
		margin: 0 auto;
		margin-top: 20px;
	}
	.cls-1{fill:#50b794;}
	
	/* Error message */
	.error {
		color: red;
		text-align: center;
	}
	
	.loading {
		max-width: 120px;
		margin: 0 auto;
		padding-top: 10px;
	}

	.icon {
		margin: 0 auto;
		margin-top: 20px;
	}
</style>