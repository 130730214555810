import helpers from '@/helpers/helpers.js';
import validate from '@/helpers/validate.js';
import { tripService } from '@/services/tripService.js';
import { travellerService } from '@/services/travellerService.js';
import { searchService } from '@/services/searchService.js';
import constants from '@/helpers/constants.js';
import Vue from 'vue';
import track from '@/helpers/track.js'

const state = {
	tripID: false,
	tripName: false,
	tripItemID: false, // Current viewed trip ITEM (for fetching specific trip Item data)
	tripItems: {},
	tripTravellers: [],
	totalTripTravellers: { Adult: [], Child: [], Infant: [] },
	userTravellers: [],
	bookingValidation: {
		valid: true,
		totalPaid: 0,
		error: "", // Only 1 error will be shown here, even if more than one validation criteria is not met
		canDeleteTrip: true, // Set to false if any trip item is already bookedll be shown here, even if more than one validation criteria is not met
	},
	flightsValidity: false,
	loading: false,
	error: null,
	pendingTravellerLoads: 0, // Determines how many active loading travellers there are (so that you can toggle multiple travellers at once)


	// All endpoints are fired and stored here, so that they can be fired before they are needed
	tripItemPromises: {}, // This is keyed by the tripItemID
	tripPromises: {}, // No key, just the different promises
}

const getters = {
	travellersLoading(state) { // If there are any pending traveller loads, return true - used to show loading spinner on the manage travellers page
		return state.pendingTravellerLoads > 0;
	},
	// Used on the manage travellers page to only show traveller related validation
	tripItemTravellersValidity(state) { 
		return tripItemID => { // TripItemID is taken as a parameter in this way
			let alerts = state.tripItems[tripItemID].alerts.traveller; // Traveller alerts only

			let validation = {
				valid: true,
				error: ""
			}

			if(alerts.length > 0) {
				let alert = alerts[0]; // Highest priority alert
				validation.valid = false;
				validation.error = alert.main_message + ". " + alert.description;
			}
			return validation;
		}
	},
	// Used on the trip item page when clicking the book button, to show all validation.
	tripItemBookingValidity(state, getters) {
		return tripItemID => { // TripItemID is taken as a parameter in this way
			// Does not include the warning alerts
			let alerts = state.tripItems[tripItemID].alerts.datesValidity
				.concat(state.tripItems[tripItemID].alerts.flightsValidity)
				.concat(state.tripItems[tripItemID].alerts.other);

			let validation = {
				valid: true,
				error: ""
			}

			if(alerts.length > 0) {
				let alert = alerts[0]; // Highest priority alert
				validation.valid = false;
				validation.error = alert.main_message + ". " + alert.description;
			}

			if(state.tripItems[tripItemID].type == 'flight') {
				// Check that we match the ATOL flight regulation requirements (every flight offer must have a tour booked alongside it). This means that we need to check total booked tours and booked flights, combined with the tours and flights they are trying to book, determine the difference (which gives us the number of flights that can to be booked)
				let bookedTripItems = getters.tripItemsCategorized.booked.items;
				let tours = [];
				if(bookedTripItems.length > 0) {
					tours = tours.concat(bookedTripItems.filter(tripItem => tripItem.type == "tour")); // All tours that are booked
				}
				// Get all unique travellers on tours
				let tourTravellers = [];
				for(let tourIndex in tours) {
					let tour = tours[tourIndex];
					for(let travellerIndex in tour.travellers) {
						let traveller = tour.travellers[travellerIndex];
						if(!tourTravellers.includes(traveller)) {
							tourTravellers.push(traveller);
						}
					}
				}
				// Determine if the flight has any passengers that are not on a tour
				let flight = state.tripItems[tripItemID];
				let flightHasPassengersNotOnATour = false;
				for(let travellerIndex in flight.travellers) {
					let traveller = flight.travellers[travellerIndex];
					let existingTraveller = tourTravellers.find(tourTraveller => tourTraveller.traveller_id == traveller.traveller_id);
					if(!existingTraveller) {
						flightHasPassengersNotOnATour = true;
						break;
					}
				}
				if(flightHasPassengersNotOnATour && 0) {
					// Overwrite any other validation issues, as this is most important
					validation.valid = false;
					validation.error = "Ensure all passengers are also booked on a tour before booking this flight. Alternatively, you can book a tour alongside this flight by checking out via the trip page.";
				}
			}

			return validation;
		}
	},
	// Used on the trip page to get validation for the entire trip
	tripBookingValidity(state, getters) {
		let alerts = [];
		let tripItems = getters.tripItemsCategorized.bookable.items.concat(getters.tripItemsCategorized.bookableWithIssues.items);
		for(let index in tripItems) { // Only bookable trip items
			let tripItem = tripItems[index];
			alerts = alerts.concat(tripItem.alerts.datesValidity)
				.concat(tripItem.alerts.flightsValidity)
				.concat(tripItem.alerts.other)
				.concat(tripItem.alerts.traveller); // Traveller included ?
		}

		let validation = {
			valid: true,
			error: ""
		}

		if(alerts.length > 0) {
			let alert = alerts[0]; // Highest priority alert
			validation.valid = false;
			validation.error = alert.main_message + ". " + alert.description;
			validation.error = "One or more of your trip items have issues. Please check your trip items and try again."
		}

		// Check that we match the ATOL flight regulation requirements (every flight offer must have a tour booked alongside it). This means that we need to check total booked tours and booked flights, combined with the tours and flights they are trying to book, determine the difference (which gives us the number of flights that can to be booked)
		let bookedTripItems = getters.tripItemsCategorized.booked.items;
		let tours = [];
		let flightsToBook = [];
		if(bookedTripItems.length > 0) {
			tours = tours.concat(bookedTripItems.filter(tripItem => tripItem.type == "tour")); // All tours that are booked
		}
		// This version, unlike single tripItemBookingValidity function, also checks the other items that are being booked, not just the booked items
		if(tripItems.length > 0) {
			tours = tours.concat(tripItems.filter(tripItem => tripItem.type == "tour")); // All tours that are trying to be booked
			flightsToBook = flightsToBook.concat(tripItems.filter(tripItem => tripItem.type == "flight")); // All flights that are trying to be booked
		}
		// Get all unique travellers on tours
		let tourTravellers = [];
		for(let tourIndex in tours) {
			let tour = tours[tourIndex];
			for(let travellerIndex in tour.travellers) {
				let traveller = tour.travellers[travellerIndex];
				if(!tourTravellers.includes(traveller)) {
					tourTravellers.push(traveller);
				}
			}
		}
		// For each flight, check that each passenger is on a tour
		let flightsWithPassengersNotOnATour = [];
		for(let flightIndex in flightsToBook) {
			let flight = flightsToBook[flightIndex];
			for(let travellerIndex in flight.travellers) {
				let traveller = flight.travellers[travellerIndex];
				let existingTraveller = tourTravellers.find(tourTraveller => tourTraveller.traveller_id == traveller.traveller_id);
				if(!existingTraveller) {
					flightsWithPassengersNotOnATour.push(flight);
				}
			}
		}
		// Make flightsWithPassengersNotOnATour unique values only based on id
		flightsWithPassengersNotOnATour = flightsWithPassengersNotOnATour.filter((flight, index, self) => self.findIndex(f => f.id === flight.id) === index);
		if(flightsWithPassengersNotOnATour.length > 0 && 0) {
			// Overwrite any other validation issues, as this is most important
			validation.valid = false;
			validation.error = "Please ensure all passengers for the following flights are also assigned to a tour: " + flightsWithPassengersNotOnATour.map(flight => flight.name).join(", ");
		}

		return validation;
	},
	// Returns trip items categorized using alerts to determine booking status
	tripItemsCategorized(state) {
		// Bookable
			// BOOKABLE: Bookable - no issues
			// BOOKABLE_WITH_ISSUES: Bookable - issues (travellers, fare etc)
		// Booked
			// BOOKED: Booked - no issues
			// BOOKED_WITH_ISSUES: Unknown - Paid and unbooked, or missing tickets etc
		// Not included in pricing
			// NOT_BOOKABLE: Not bookable - issues (expired etc)
			
		let tripItemsArray = Object.values(state.tripItems);
		let categorized = {
			bookable: { items: [], totalPrice: 0, totalCo2: { valid: false, amount: 0, } },
			bookableWithIssues: { items: [], totalPrice: 0, totalCo2: { valid: false, amount: 0, } },
			booked: { items: [], totalPrice: 0, totalCo2: { valid: false, amount: 0, } },
			bookedWithIssues: { items: [], totalPrice: 0, totalCo2: { valid: false, amount: 0, } },
			notBookable: { items: [], totalPrice: 0, totalCo2: { valid: false, amount: 0, } },
			debugNotCategorized: { items: [], totalPrice: 0, totalCo2: { valid: false, amount: 0, } },
		};

		// Is booked
		categorized.booked.items = tripItemsArray.filter(tripItem => tripItem.bookingStatus.code === 'booked');
		categorized.booked.items.forEach(f => tripItemsArray.splice(tripItemsArray.findIndex(e => e.id === f.id),1)); //remove these items from the tripItemsArray
		categorized.booked.totalPrice = categorized.booked.items.reduce((a, b) => Math.round((parseFloat(a) + parseFloat(b.price)) * 100) / 100, 0);
		categorized.booked.totalCo2.amount = categorized.booked.items.reduce((a, b) => a + b.totalCo2, 0);
		categorized.booked.totalCo2.valid = categorized.booked.items.every(tripItem => tripItem.totalCo2 !== false);

		// Has unknown status. Should we add check for totalPaid > 0 too? 
		categorized.bookedWithIssues.items = tripItemsArray.filter(tripItem => tripItem.bookingStatus.code === 'unknown');
		categorized.bookedWithIssues.items.forEach(f => tripItemsArray.splice(tripItemsArray.findIndex(e => e.id === f.id),1)); //remove these items from the tripItemsArray
		categorized.bookedWithIssues.totalPrice = categorized.bookedWithIssues.items.reduce((a, b) => Math.round((parseFloat(a) + parseFloat(b.price)) * 100) / 100, 0);
		categorized.bookedWithIssues.totalCo2.amount = categorized.bookedWithIssues.items.reduce((a, b) => a + b.totalCo2, 0);
		categorized.bookedWithIssues.totalCo2.valid = categorized.bookedWithIssues.items.every(tripItem => tripItem.totalCo2 !== false);

		// Has no alerts (expect warnings) and isn't booked
		categorized.bookable.items = tripItemsArray.filter(tripItem => {
			return tripItem.bookingStatus.code === 'not_booked' 
				&& tripItem.alerts.datesValidity.length === 0 
				&& tripItem.alerts.flightsValidity.length === 0 
				&& tripItem.alerts.other.length === 0 
				&& tripItem.alerts.traveller.length === 0
		});
		categorized.bookable.items.forEach(f => tripItemsArray.splice(tripItemsArray.findIndex(e => e.id === f.id),1)); //remove these items from the tripItemsArray
		categorized.bookable.totalPrice = categorized.bookable.items.reduce((a, b) => Math.round((parseFloat(a) + parseFloat(b.price)) * 100) / 100, 0);
		categorized.bookable.totalCo2.amount = categorized.bookable.items.reduce((a, b) => a + b.totalCo2, 0);
		categorized.bookable.totalCo2.valid = categorized.bookable.items.every(tripItem => tripItem.totalCo2 !== false);

		// Has alerts (expect major alerts such as expired flight) and isn't booked
		categorized.bookableWithIssues.items = tripItemsArray.filter(tripItem => {
			return tripItem.bookingStatus.code === 'not_booked' 
				&& (tripItem.alerts.datesValidity.length > 0 
					|| tripItem.alerts.flightsValidity.length > 0 
					|| tripItem.alerts.other.length > 0 
					|| tripItem.alerts.traveller.length > 0
					)
				&& tripItem.alerts.all.find(alert => alert.alert_code === 'red') === undefined // None of the alerts are major that the issue can't be fixed
		});
		categorized.bookableWithIssues.items.forEach(f => tripItemsArray.splice(tripItemsArray.findIndex(e => e.id === f.id),1)); //remove these items from the tripItemsArray
		categorized.bookableWithIssues.totalPrice = categorized.bookableWithIssues.items.reduce((a, b) => Math.round((parseFloat(a) + parseFloat(b.price)) * 100) / 100, 0);
		categorized.bookableWithIssues.totalCo2.amount = categorized.bookableWithIssues.items.reduce((a, b) => a + b.totalCo2, 0);
		categorized.bookableWithIssues.totalCo2.valid = categorized.bookableWithIssues.items.every(tripItem => tripItem.totalCo2 !== false);

		// Has major alerts (such as expired flight) and isn't booked
		categorized.notBookable.items = tripItemsArray.filter(tripItem => {
			return tripItem.bookingStatus.code === 'not_booked'
				&& tripItem.alerts.all.find(alert => alert.alert_code === 'red') !== undefined // Has a major alert
		});
		categorized.notBookable.items.forEach(f => tripItemsArray.splice(tripItemsArray.findIndex(e => e.id === f.id),1)); //remove these items from the tripItemsArray
		categorized.notBookable.totalPrice = categorized.notBookable.items.reduce((a, b) => Math.round((parseFloat(a) + parseFloat(b.price)) * 100) / 100, 0);
		categorized.notBookable.totalCo2.amount = categorized.notBookable.items.reduce((a, b) => a + b.totalCo2, 0);
		categorized.notBookable.totalCo2.valid = categorized.notBookable.items.every(tripItem => tripItem.totalCo2 !== false);
		// For debugging if anything doesn't get categorized - remaining items
		categorized.debugNotCategorized.items = tripItemsArray;
		categorized.debugNotCategorized.totalPrice = categorized.debugNotCategorized.items.reduce((a, b) => Math.round((parseFloat(a) + parseFloat(b.price)) * 100) / 100, 0);
		categorized.debugNotCategorized.totalCo2.amount = categorized.debugNotCategorized.items.reduce((a, b) => a + b.totalCo2, 0);
		categorized.debugNotCategorized.totalCo2.valid = categorized.debugNotCategorized.items.every(tripItem => tripItem.totalCo2 !== false);

		return categorized;
	},

	/**
	 * Return possible search values for a flight, that can be used to conduct a search similar to that that found the flight originally.
	 * Can be used to prefill the search values (flightsSearch state) based on an existing trip item. Used for the search process of selecting a new flight from an existing one.
	 * This is used when a user selects a new flight from the search results, and we want to prefill the search form with the values from the existing flight.
	 * The flightsSearch module uses this function to prefill the search form when a user selects a new flight from the search results.
	 * @param {num} tripID  the tripID for the trip
	 * @param {num} tripItemID  the tripItemID for the trip
	 */
	//
	getFlightSearchValues: (state, getters, rootState) => (tripItemID) => {
		let tripItem = state.tripItems[tripItemID];
		if(tripItem && tripItem.data) {
			let searchValues = {};

			searchValues.tripType = tripItem.data.return ? "return" : "oneway";
			searchValues.startLocation = tripItem.data.outbound.origin;
			searchValues.endLocation = tripItem.data.outbound.destination;
			if(tripItem.data.return) {
				searchValues.dates = { 
					start: new Date(tripItem.data.outbound.departureDisplay), 
					end: new Date(tripItem.data.return.arrivalDisplay)
				};
			} else {
				searchValues.dates = new Date(tripItem.data.outbound.departureDisplay);
			}

			if(tripItem.data.fare) { // Expired flights have no fare - which means we are missing some data
				// We have fare, so use required passengers
				searchValues.qty = {
					adults: tripItem.totalRequiredTravellers.Adult,
					children: tripItem.totalRequiredTravellers.Child,
					infants: tripItem.totalRequiredTravellers.Infant,
				}
				// Use fare defined cabin class
				searchValues.cabinClass = rootState.flightsSearch.searchOptions.cabinClassOptions.filter(ele => ele.code == tripItem.data.fare.cabin_class)[0]; // Select cabin class from options
			} else {
				// Else no fare, use the passengers on the trip
				searchValues.qty = {
					adults: tripItem.totalTravellers.Adult,
					children: tripItem.totalTravellers.Child,
					infants: tripItem.totalTravellers.Infant,
				}
				// Default cabinClass to economy
				searchValues.cabinClass = "Economy";
			}
			
			return searchValues;
		}
	},
}

const mutations = {
	SET_LOADING(state, loading) {
		state.loading = loading;
	},
	SET_ERROR(state, error) {
		state.error = error;
	},
	SET_TRAVELLERS_LOADING(state, loading) {
		// Increment if true, decrement if false
		if(loading) {
			state.pendingTravellerLoads++;
		} else {
			state.pendingTravellerLoads--;
		}
	},
	SET_TRIP_ID(state, tripID) {
		state.tripID = tripID;
	},
	SET_TRIP_ITEM_ID(state, tripID) {
		state.tripItemID = tripID;
	},
	SET_TRIP_NAME(state, payload) {
		helpers.set(state, ['tripName'], payload.tripName)
	},
	SET_TRIP_IMAGE(state, payload) {
		helpers.set(state, ['tripImage'], payload.tripImage)
	},
	SET_TRIP_TRAVELLERS(state, payload) {
		helpers.set(state, ['tripTravellers'], payload.tripTravellers)
		helpers.set(state, ['totalTripTravellers'], payload.totalTravellers)
	},
	SET_USER_TRAVELLERS(state, payload) {
		helpers.set(state, ['userTravellers'], payload.userTravellers)
	},
	RESET_TRIP_ITEMS(state) {
		helpers.set(state, ['tripItems'], {})
	},
	SET_TRIP_ITEM(state, payload) {
		helpers.set(state, ['tripItems', payload.tripItemID], payload.tripItemData)
	},
	SET_TOUR_DEPARTURE(state, payload) {
		helpers.set(state, ['tripItems', payload.tripItemID, 'data', 'rawTourDepartureData'], payload.tourDeparture);
		helpers.set(state, ['tripItems', payload.tripItemID, 'data', 'availability'], payload.tourDeparture.availability);
		helpers.set(state, ['tripItems', payload.tripItemID, 'data', 'roomOptions'], payload.tourDeparture.rooms);
		helpers.set(state, ['tripItems', payload.tripItemID, 'data', 'startDate'], payload.tourDeparture.start_date);
		helpers.set(state, ['tripItems', payload.tripItemID, 'data', 'finishDate'], payload.tourDeparture.finish_date);
	},
	SET_BOOKING_VALIDATION(state, payload) { // TODO: Split this - set_total_paid, and a getter for canDeleteTrip. Remove "bookingValidation" property
		if(payload.hasOwnProperty('totalPaid')) { // If this was passed over
			helpers.set(state, ['bookingValidation', 'totalPaid'], payload.totalPaid)
		}
		if(payload.hasOwnProperty('canDeleteTrip')) { // If this was passed over
			helpers.set(state, ['bookingValidation', 'canDeleteTrip'], payload.canDeleteTrip)
		}
	},
	SET_TRIP_DATES(state, payload) {
		helpers.set(state, ['tripDates'], payload.tripDates);
	},
	SET_FARE(state, payload) {
		helpers.set(state, ['tripItems', payload.tripItemID, 'data', 'fare'], payload.fare);
		helpers.set(state, ['tripItems', payload.tripItemID, 'data', 'fareKey'], payload.fareKey);
	},
	SET_ROOM(state, payload) {
		helpers.set(state, ['tripItems', payload.tripItemID, 'data', 'room'], payload.room);
		helpers.set(state, ['tripItems', payload.tripItemID, 'data', 'roomCode'], payload.roomCode);
	},
	SET_PRICE(state, payload) {
		helpers.set(state, ['tripItems', payload.tripItemID, 'price'], payload.price);
	},
	SET_TRIP_ITEM_TRAVELLERS(state, payload) {
		helpers.set(state, ['tripItems', payload.tripItemID, 'travellers'], payload.travellers);
	},
	SET_TRIP_ITEM_TRAVELLERS_OTHER_DATA(state, payload) {
		helpers.set(state, ['tripItems', payload.tripItemID, 'totalTravellers'], payload.totalTravellers);
		helpers.set(state, ['tripItems', payload.tripItemID, 'requiredTravellers'], payload.requiredTravellers);
		helpers.set(state, ['tripItems', payload.tripItemID, 'totalRequiredTravellers'], payload.totalRequiredTravellers);
		helpers.set(state, ['tripItems', payload.tripItemID, 'travellerTypePricing'], payload.travellerTypePricing);
	},
	SET_FLIGHTS_VALIDITY(state, payload) {
		// helpers.set(state, ['flightsValidity'], payload);

		let testPayload = payload;
		// testPayload = {
		// 	"price_good": [],
		// 	"price_change": {
		// 		"5593": {
		// 			"old_price": "113.32",
		// 			"new_price": "113.22"
		// 		}
		// 	},
		// 	// "flight_invalid": {
		// 	// 	"5593": "Date is in the past or too far in the future",
		// 	// }
		// };

		helpers.set(state, ['flightsValidity'], testPayload);
	},
	SET_TRIP_ITEM_TRAVELLER_ALERT(state, payload) {
		if(payload.alert) {
			state.tripItems[payload.tripItemID].alerts.traveller.push(payload.alert);
		} else {
			state.tripItems[payload.tripItemID].alerts.traveller = []; // Reset if there is no alert
		}
	},
	SET_ALL_ALERTS(state, payload) {
		// Update the 'all' total array of alerts
		state.tripItems[payload.tripItemID].alerts.all = 
			state.tripItems[payload.tripItemID].alerts.datesValidity
			.concat(state.tripItems[payload.tripItemID].alerts.flightsValidity) // Prevents the user from trying to book
			.concat(state.tripItems[payload.tripItemID].alerts.traveller) // Traveller alerts redirect the user to the pax management screen when trying to book
			.concat(state.tripItems[payload.tripItemID].alerts.warning) // Warning alerts do not block booking
			.concat(state.tripItems[payload.tripItemID].alerts.other); // Prevents the user from trying to book

		// Sort the alerts by priority
		state.tripItems[payload.tripItemID].alerts.all.sort((a, b) => {
			return b.priority - a.priority;
		});

		// Current flight priorities are (this affects which 1 is shown, higher is more important):
		// 100 - Unknown booking status alert
		// 95 - Data issue alert for booked flights
		// 80 - Unbooked flight has expired
		// 75 - Capacity too low
		// 70 - No Fare selected (from flightsValidity endpoint check)
		// 60 - The price has changed
		// 50 - Traveller validation
		// 10 - Last day for booking warning
	}
}	
const actions = {
	/**
	 * Ensures that all data is fetched for a trip. Call appropriate actions only if required
	 * Should be fire on created() for any page that requires trip data
	 */
	async initTrip({ state, dispatch, commit }, { tripID, tripItemID = false, force = false }) {
		try {
			state.error = null; // Reset any errors

			if(state.tripID != tripID || force) { // If we are not already on this trip, or we are forcing a refresh
				// If this trip is not in state yet, fetch it
				return dispatch('getTripData', { tripID, tripItemID }); // This also handles user pax fetching
			}
			if(tripItemID && state.tripItemID != tripItemID) { // We have changed which item we are viewing
				if(state.tripItems[tripItemID]) { // If this trip item is already in state (is not a new trip item that was just added)
					commit('SET_TRIP_ITEM_ID', tripItemID); // Select it 
					await dispatch('fetchUserTravellers', { }); //re-fetch user passengers (needed because we need to redetermine pax ages using flight departure date)
				} else {
					return dispatch('getTripData', { tripID, tripItemID }); // Fetch all data to ensure we get the newly added trip item
				}
			}
			
		} catch(error) {
			Vue.prototype.$rollbar.error(error);
			commit('SET_LOADING', false);
		}
	},
	
	/**
	 * Fetches all tripItem data for a given trip (or specified trip item)
	 *
	 * @param {num} tripID the tripID for the trip
	 * @param {num} tripItemID the tripItemID. Optional. If specified, only this tripItem is returned. Otherwise all tripItems for the given Trip is returned.
	 * @param {return} return returns timespan in format: 2h 25m
	 * @public This is a public method
	*/
	async getTripData({ commit, dispatch, state }, { tripID, tripItemID = false }) {
		try {
			commit('SET_LOADING', true);
			commit('SET_TRIP_ID', tripID);
			commit('RESET_TRIP_ITEMS') // Reset trip items to empty

			if(tripItemID == null) {
				tripItemID = false;
			}
			commit('SET_TRIP_ITEM_ID', tripItemID); // Currently viewed item

			dispatch('fetchTripSpecificData', { tripID }); // No await needed for this? 
			
			let tripItemsPromise = tripService.fetchTripItems(tripID);
			let [tripItemsResponse] = await Promise.all([tripItemsPromise]);


			let totalPaid = 0;
			let tripDates = {
				start: false,
				end: false,
			}

			// Set the trip items
			let tripItems = [];
			if(Array.isArray(tripItemsResponse.data.data)) {
				tripItems = tripItemsResponse.data.data;
			} else {
				tripItems.push(tripItemsResponse.data.data);
			}

			// Order trip items by date
			tripItems.sort(function(a,b) { return (a.trip_item.data.date > b.trip_item.data.date) ? 1 : ((b.trip_item.data.date > a.trip_item.data.date) ? -1 : 0);} );

			dispatch('fireTripPromises', { tripID, tripItems }); // Fire all the required promises for the trip items

			let unbookedFlightIDs = [];
			for(let i = 0; i < tripItems.length; i++) {
				let tripItem = tripItems[i].trip_item;

				let pax = tripItems[i].pax;
				
				let booked = tripItem.status == "booked";
				// let booked = tripItem.paid > 0;
				let tripItemObj = {
					id: tripItem.id,
					type: tripItem.type,
					name: "", // Displayed in various places
					price: tripItem.data.price,
					date: false, // Set based on specific trip item type section
					bookingStatus: {
						code: booked ? "booked" : "not_booked",
						totalPaid: tripItem.paid,
						text: booked ? "Booked" : "Not Booked", 
						bookingID: booked ? tripID + "_" + tripItem.id : null,
					},
					alerts: { // Different arrays for each section needed to allow resetting alerts for e.g. travellers, without wiping flightsValidity etc
						flightsValidity: [],
						traveller: [],
						other: [],
						warning: [],
						datesValidity: [],
						all: [] // Combined arrays of the other alerts
					},
					totalCo2: false,
					purposeScore: false,
					rawFetchTripItemsData: { tripItem, pax }, // Should be removed eventually, any required data stored as own property.
				}

				// Check for a payment but not booked - issue happened
				if(tripItemObj.bookingStatus.code == "not_booked" && tripItemObj.bookingStatus.totalPaid > 0) {
					// Don't set the booking status to unknown - as that messes up the processing message on the confirmation page.
					tripItemObj.alerts.other.push({
						alert_code: "red",
						alert_type: "issue",
						priority: 100, // Highest priority - no other alerts are relevant if this is the case
						main_message: "Something went wrong",
						description: `Looks like the booking didn't go through. Our team is looking into this and will be in touch shortly. Please keep an eye on your emails. If you keep having issues, please contact support for further assistance using the booking reference number ${ tripID }${tripItemID ? '_' + tripItemID : ''} at ${constants.supportEmailLink()}`,
						secondary_message: "Please be patient while someone investigates this issue. We will be in touch shortly.",
					});
				}

				if(tripItemObj.bookingStatus.code == "booked" || tripItemObj.bookingStatus.code == "unknown" || tripItemObj.bookingStatus.totalPaid > 0) {
					commit('SET_BOOKING_VALIDATION', { canDeleteTrip: false });
				}
				totalPaid += tripItemObj.bookingStatus.totalPaid; // Sum up the item payments

				// Trip item type specific data.
				if(tripItemObj.type == "flight") { // Only flight related trip item data
					// // Both booked and non-booked flights data
					tripItemObj.data = { // Data property is specific to tripItem TYPE - e.g. flights / hotels / tours
						outboundKey: tripItem.data.outbound_key,
						returnKey: tripItem.data.return_key !== undefined ? tripItem.data.return_key : "",
						fareKey: tripItem.data.fare,
						fare: false,
						fareOptions: false,
						outbound: {},
						return: false, // Set below if return flights exist
						flights: {}, // All flights on this tripItem (including return) - grouped by flight date
					}

					// All flights (booked or unbooked)
					if(tripItem.flight_details) {
						tripItemObj.data.flightsDetails = tripItem.flight_details; 
						let outboundTotalCo2 = 0;
						let outboundFlightsArray = [];
						for(const id in tripItemObj.data.flightsDetails.flights) {
							let flight = tripItemObj.data.flightsDetails.flights[id];
							let flightData = {
								departureTime: flight.segment_info.DepartureTime,
								arrivalTime: flight.segment_info.ArrivalTime,
								origin: flight.segment_info.Origin,
								originData: {
									code: flight.segment_info.Origin,
									name: flight.segment_info.Origin, // Don't have this info from amadeus yet, use code instead
								},
								destination: flight.segment_info.Destination,
								destinationData: {
									code: flight.segment_info.Destination,
									name: flight.segment_info.Destination, // Don't have this info from amadeus yet, use code instead
								},
								carrier: {
									code: flight.carrier.code,
									name: flight.carrier.name,
								},
								flightTime: flight.flight_time,
								flightNumber: flight.flight_number,
								changeOfPlane: flight.segment_info.ChangeOfPlane,
								co2: {
									amount: flight.co2,
									unit: flight.co2_unit,
								},
								isFlown: false, // Always false for unbooked flights - no info from amadeus about flown status,  but if it's flown it wouldn't be a valid offer anyway
							}
							outboundTotalCo2 += flight.co2;
							outboundFlightsArray.push(flightData);
						}
						let outboundLayoverLocations = outboundFlightsArray.map(x => x.destination.name);
						outboundLayoverLocations.pop();

						// tripItemObj.date = Object.values(getFlightsResponse.data.data.flights)[0].departure_display;

						tripItemObj.data.outbound = {
							origin: tripItemObj.data.flightsDetails.origin,
							destination: tripItemObj.data.flightsDetails.destination,
							departureDisplay: tripItemObj.data.flightsDetails.departure_display,
							arrivalDisplay: tripItemObj.data.flightsDetails.arrival_display,
							layoversTotal: outboundFlightsArray.length - 1,
							layoverLocations: outboundLayoverLocations,
							travelTime: tripItemObj.data.flightsDetails.travel_time,
							flightsArray: outboundFlightsArray,
							totalCo2: outboundTotalCo2,
							allFlightsFlown: false, // Always false for unbooked flights.
						};
						
						if(tripItemObj.data.flightsDetails.return_flight) {
							let returnTotalCo2 = 0;
							let returnFlightsArray = [];
							for(const id in tripItemObj.data.flightsDetails.return_flight.flights) {
								let flight = tripItemObj.data.flightsDetails.return_flight.flights[id];

								let flightData = {
									departureTime: flight.segment_info.DepartureTime,
									arrivalTime: flight.segment_info.ArrivalTime,
									origin: flight.segment_info.Origin,
									originData: {
										code: flight.segment_info.Origin,
										name: flight.segment_info.Origin, // Don't have this info from amadeus yet, use code instead
									},
									destination: flight.segment_info.Destination,
									destinationData: {
										code: flight.segment_info.Destination,
										name: flight.segment_info.Destination, // Don't have this info from amadeus yet, use code instead
									},
									carrier: {
										code: flight.carrier.code,
										name: flight.carrier.name,
									},
									flightTime: flight.flight_time,
									flightNumber: flight.flight_number,
									changeOfPlane: flight.segment_info.ChangeOfPlane,
									co2: {
										amount: flight.co2,
										unit: flight.co2_unit,
									},
									isFlown: false, // Always false for unbooked flights - no info from amadeus about flown status,  but if it's flown it wouldn't be a valid offer anyway
								}
								returnTotalCo2 += flight.co2;
								returnFlightsArray.push(flightData);
							}
							let returnLayoverLocations = returnFlightsArray.map(x => x.destination.name);
							returnLayoverLocations.pop();
							
							tripItemObj.data.return = {
								origin: tripItemObj.data.flightsDetails.return_flight.origin,
								destination: tripItemObj.data.flightsDetails.return_flight.destination,
								departureDisplay: tripItemObj.data.flightsDetails.return_flight.departure_display,
								arrivalDisplay: tripItemObj.data.flightsDetails.return_flight.arrival_display,
								layoversTotal: returnFlightsArray.length - 1,
								layoverLocations: returnLayoverLocations,
								travelTime: tripItemObj.data.flightsDetails.return_flight.travel_time,
								flightsArray: returnFlightsArray,
								totalCo2: returnTotalCo2,
								allFlightsFlown: false, // Always false for unbooked flights.
							};
						}

						// Selected Fare
						if(Array.isArray(tripItemObj.data.flightsDetails.fares)) {
							tripItemObj.data.fareOptions = tripItemObj.data.flightsDetails.fares;
							if(tripItemObj.data.fareKey) { // If fareKey provided, determine specific fare information + Pax requirement information
								tripItemObj.data.fare = tripItemObj.data.flightsDetails.fares.find(item=>item.key == tripItemObj.data.fareKey);
							} else {
								tripItemObj.data.fare = false;  // No selected fare
							}
						} else {
							console.error("Issue getting Fare data from Amadeus");
						}

						// Sustainability data
						tripItemObj.totalCo2 = tripItemObj.data.flightsDetails.total_co2;
						tripItemObj.purposeScore = tripItemObj.data.flightsDetails.purpose_score;

						if(tripItemObj.data.outbound.departureDisplay) {
							// Booking Deadline Approaching
							let now = new Date();
							let departure = new Date(tripItemObj.data.outbound.departureDisplay); // Is the departure the last booking moment? Or another moment (likely)?
							let difference = departure.getTime() - now.getTime();
							let daysDifference = Math.floor(difference / (1000 * 3600 * 24));
							if(daysDifference < 0) {
								tripItemObj.alerts.datesValidity.push({
									alert_code: "red",
									alert_type: "expiry",
									priority: 80, 
									main_message: "Flight expired",
									secondary_message: "Flight expired",
									description: "The selected flight is in the past. Please select a new flight offer, or remove the flight from the trip",
									data: { // Specific data for this alert_type
										reason: "Flight date has passed",
									}
								});
							} else if(daysDifference < 4) {
								tripItemObj.alerts.datesValidity.push({
									alert_code: "red",
									alert_type: "expiry",
									priority: 80, 
									main_message: "Flight too soon",
									secondary_message: "Flight too soon",
									description: "As a temporary precaution, flights can't be booked within 72 hours of departure. Please select a new flight offer, or remove the flight from the trip",
									data: { // Specific data for this alert_type
										reason: "Flight departure is too soon",
									}
								});
							} else if(daysDifference < 8) {
								let daysRemaining = daysDifference - 3;
								let main_message = "Last " + daysRemaining + " days for booking";
								let description = "Your flight is in " + daysDifference + " days. Please be sure to book 3 days in advance of departure to secure this flight."
								if(daysRemaining <= 1 && daysRemaining >= 0) {
									main_message = "Last day for booking";
									description = "Please make your booking to secure this flight."
									if(daysRemaining == 0) {
										description = "Please make your booking to secure this flight."
									}
								}
								tripItemObj.alerts.warning.push({
									alert_code: "blue",
									alert_type: "deadline",
									priority: 10, // Lowest priority - every other alert is probably more important
									main_message: main_message,
									secondary_message: "Booking is waiting",
									description: description,
									data: {
										days: daysDifference,
									}
								});
							}
						}
					} 

					if(tripItemObj.bookingStatus.code != "booked") {
						unbookedFlightIDs.push(tripItems[i].trip_item.id);
					}

					// Booked flights only - overwrite any data from the normal flight details with booking specific details.
					// Don't overwrite anything that the booked endpoint doesn't give us (e.g. Co2 data)
					if(tripItemObj.bookingStatus.code == "booked") {
						let bookedFlightDetails = await state.tripItemPromises[tripItem.id].bookedFlightDetails;

						if(!bookedFlightDetails) { // If promise failed, 
							tripItemObj.bookingStatus.code = "unknown"; // Mark as unknown booked status, so that it will still try to fetch unbooked flight data as a fallback;
							tripItemObj.bookingStatus.text = "Unknown";
							tripItemObj.alerts.other.push({
								alert_code: "red",
								alert_type: "issue",
								priority: 100, // Highest priority - no other alerts are relevant if this is the case
								main_message: "Something went wrong",
								description: `Our team is looking into this and will be in touch shortly. Please keep an eye on your emails. If you keep having issues, please contact support for further assistance using the booking reference number ${ tripID }${tripItemID ? '_' + tripItemID : ''} at ${constants.supportEmailLink()}`,
								secondary_message: "Please be patient while someone investigates this issue. We will be in touch shortly.",
							});
							// error = "It looks like there may have been a problem with this booking. Please contact support for further assistance at " + constants.supportEmailLink() + "";
						}

						if(bookedFlightDetails && bookedFlightDetails.data.data.length > 0) {
							let outboundFlights = tripItemObj.data.outbound.flightsArray; // The existing flights
							let bookedFlightSegments = bookedFlightDetails.data.data[0].itineraries[0].segments;
							let allOutboundFlightsFlown = true; // Mark as false if any flight is not flown
							outboundFlights.forEach((flight, index, array) => {
								let segmentData = bookedFlightSegments[index];
								console.log(segmentData);
								// Update the flight data with the booked flight data
								array[index].departureTime = segmentData.departure.at;
								array[index].arrivalTime = segmentData.arrival.at;
								array[index].origin = segmentData.departure.iataCode;
								array[index].originData = {
									code: segmentData.departure.iataCode,
									name: segmentData.departure.iataName,
								};
								array[index].destination = segmentData.arrival.iataCode;
								array[index].destinationData = {
									code: segmentData.arrival.iataCode,
									name: segmentData.arrival.iataName,
								};
								array[index].carrier = {
									code: segmentData.carrierCode,
									name: segmentData.carrierName, // We don't have this on this endpoint
								};
								array[index].flightTime = segmentData.duration; // This needs to be converted to minutes?
								array[index].flightNumber = segmentData.number;
								array[index].changeOfPlane = bookedFlightDetails.data.data[0].itineraries[0].changeOfPlane; // We don't have this on this endpoint? Can we calculate it?
								array[index].terminal = segmentData.departure.terminal;
								array[index].fareDetails = bookedFlightDetails.data.data[0].tickets.length > 0 ? bookedFlightDetails.data.data[0].tickets[0].fare_details.find(el => el.segmentId == flight.segmentId) : false,// All ticket data should be the same per passenger, so we can just use the first ticket.
								array[index].isFlown = segmentData.isFlown;

								if(!segmentData.isFlown) {
									allOutboundFlightsFlown = false;
								}
							});
							tripItemObj.data.outbound.flightsArray = outboundFlights; // Update the tripItemObj with the new flight data
							let outboundLayoverLocations = outboundFlights.map(x => x.destination);
							outboundLayoverLocations.pop();

							tripItemObj.data.outbound.origin = bookedFlightSegments[0].departure.iataCode;
							tripItemObj.data.outbound.destination = bookedFlightSegments[bookedFlightSegments.length-1].arrival.iataCode;
							tripItemObj.data.outbound.departureDisplay = bookedFlightSegments[0].departure.at;
							tripItemObj.data.outbound.arrivalDisplay = bookedFlightSegments[bookedFlightSegments.length-1].arrival.at;
							tripItemObj.data.outbound.travelTime = bookedFlightDetails.data.data[0].itineraries[0].total_travel_time;
							tripItemObj.data.outbound.layoversTotal = bookedFlightSegments.length-1;
							tripItemObj.data.outbound.layoverLocations = outboundLayoverLocations;
							tripItemObj.data.outbound.flightsArray = outboundFlights;
							tripItemObj.data.outbound.allFlightsFlown = allOutboundFlightsFlown;
							// tripItemObj.data.outbound.totalCo2 = outboundTotalCo2;

							if(tripItemObj.data.return && tripItemObj.data.return.flightsArray.length > 0) {
								let returnFlights = tripItemObj.data.return.flightsArray; 
								let returnBookedFlightSegments = false;
								let allReturnFlightsFlown = true; // Mark as false if any flight is not flown
								if(bookedFlightDetails.data.data[0].itineraries.length >= 2) {
									returnBookedFlightSegments = bookedFlightDetails.data.data[0].itineraries[1].segments;
								}
								
								returnFlights.forEach((flight, index, array) => {
									let segmentData = returnBookedFlightSegments[index];
									// Update the flight data with the booked flight data
									array[index].departureTime = segmentData.departure.at;
									array[index].arrivalTime = segmentData.arrival.at;
									array[index].origin = segmentData.departure.iataCode;
									array[index].originData = {
										code: segmentData.departure.iataCode,
										name: segmentData.departure.iataName,
									};
									array[index].destination = segmentData.arrival.iataCode;
									array[index].destinationData = {
										code: segmentData.arrival.iataCode,
										name: segmentData.arrival.iataName,
									};
									array[index].carrier = {
										code: segmentData.carrierCode,
										name: segmentData.carrierName, // We don't have this on this endpoint
									};
									array[index].flightTime = segmentData.duration; // This needs to be converted to minutes?
									array[index].flightNumber = segmentData.number;
									array[index].changeOfPlane = bookedFlightDetails.data.data[0].itineraries[0].changeOfPlane; // We don't have this on this endpoint? Can we calculate it?
									array[index].terminal = segmentData.departure.terminal;
									array[index].fareDetails = bookedFlightDetails.data.data[0].tickets.length > 0 ? bookedFlightDetails.data.data[0].tickets[0].fare_details.find(el => el.segmentId == flight.segmentId) : false,// All ticket data should be the same per passenger, so we can just use the first ticket.
									array[index].isFlown = segmentData.isFlown;

									if(!segmentData.isFlown) {
										allReturnFlightsFlown = false;
									}
								});
								tripItemObj.data.return.flightsArray = returnFlights; // Update the tripItemObj with the new flight data
								let returnLayoverLocations = returnFlights.map(x => x.destination);
								returnLayoverLocations.pop();

								tripItemObj.data.return.origin = returnBookedFlightSegments[0].departure.iataCode;
								tripItemObj.data.return.destination = returnBookedFlightSegments[returnBookedFlightSegments.length-1].arrival.iataCode;
								tripItemObj.data.return.departureDisplay = returnBookedFlightSegments[0].departure.at;
								tripItemObj.data.return.arrivalDisplay = returnBookedFlightSegments[returnBookedFlightSegments.length-1].arrival.at;
								tripItemObj.data.return.travelTime = bookedFlightDetails.data.data[0].itineraries[1].total_travel_time;
								tripItemObj.data.return.layoversTotal = returnBookedFlightSegments.length-1;
								tripItemObj.data.return.layoverLocations = returnLayoverLocations;
								tripItemObj.data.return.flightsArray = returnFlights;
								tripItemObj.data.outbound.allFlightsFlown = allReturnFlightsFlown;
								// tripItemObj.data.return.totalCo2 = returnTotalCo2;
							}
							
							tripItemObj.bookingStatus.PNR = bookedFlightDetails.data.data[0].booking_reference[0].reference;
							tripItemObj.data.tickets = bookedFlightDetails.data.data[0].tickets;
							tripItemObj.rawBookedFlightDetails = bookedFlightDetails.data.data[0];

							// Map segments to an array using segmentId as the keys
							let segments = [];
							bookedFlightDetails.data.data[0].itineraries.forEach((itinerary) => {
								itinerary.segments.forEach((segment) => {
									segments.push(segment);
								})
							});
							segments = segments.reduce((key, el) => ({...key,[el.segmentId]:el}),{});
							tripItemObj.bookedData = {
								segments: segments,
							}

							if(tripItemObj.data.tickets.length == 0) {
								// No tickets? This is a booking issue. Mark as unknown booking.
								// tripItemObj.bookingStatus.code = "unknown"; // Mark as unknown booked status, so that it will still try to fetch unbooked flight data as a fallback;
								// tripItemObj.bookingStatus.text = "Unknown";
								tripItemObj.alerts.other.push({
									alert_code: "yellow",
									alert_type: "issue",
									priority: 99, 
									main_message: "E-Tickets not issued",
									secondary_message: "Please contact the airline to issue your e-tickets",
									description: `If you have further questions, contact us using the reference ${ tripID }${tripItem.id ? '_' + tripItem.id : ''} at ${constants.supportEmailLink()}`,
								});
								// error = "It looks like there may have been a problem with this booking. Please contact support for further assistance at " + constants.supportEmailLink() + "";

							} 
							if(bookedFlightDetails.data.data[0].warning) { // This can happen if the record is cached but can not be fetched from Amadeus - meaning it may be old data
								// Generic warning message if we don't know the exact issue.
								let message = "There could be a problem with this booking. Please check with the airline for up to date information"
								if(bookedFlightDetails.data.data[0].warning == "Record may be out of date") {
									message = "The data displayed on this page may be out of date. Please check with the airline for up to date information";
								}
								tripItemObj.alerts.warning.push({
									alert_code: "yellow",
									alert_type: "data",
									priority: 95, 
									main_message: "Possible data issue",
									secondary_message: "Please check with the airline for up to date information",
									description: message,
								});
							}
						}
					}

					// // Stuff that happens after main data is loaded. Based on data that we know is fetched for both booked and non-booked flights (even if they have different data sources)
					// Dates
					if(tripItemObj.data.outbound) {
						tripItemObj.date = tripItemObj.data.outbound.departureDisplay; // Needed for tripItem component (at level where we don't know if it's a flight/tour etc)

						// Name
						if(tripItemObj.data.outbound.flightsArray && tripItemObj.data.outbound.flightsArray.length > 1) {
							tripItemObj.name = "Flights to " + tripItemObj.data.outbound.destination
						} else { // Not plural
							tripItemObj.name = "Flight to " + tripItemObj.data.outbound.destination
						}

						// Start and end dates for the trip.
						if(tripItemObj.data.outbound.departureDisplay < tripDates.start || !tripDates.start) {
							tripDates.start = tripItemObj.data.outbound.departureDisplay;
						}
						if(tripItemObj.data.outbound.arrivalDisplay > tripDates.end || !tripDates.end) {
							tripDates.end = tripItemObj.data.outbound.arrivalDisplay;
						}
						// Start and end dates for the trip -- return
						if(tripItemObj.data.return.departureDisplay < tripDates.start || !tripDates.start) {
							tripDates.start = tripItemObj.data.return.departureDisplay;
						}
						if(tripItemObj.data.return.arrivalDisplay > tripDates.end || !tripDates.end) {
							tripDates.end = tripItemObj.data.return.arrivalDisplay;
						}

						// All flights
						tripItemObj.data.flights = tripItemObj.data.outbound.flightsArray;
						if(tripItemObj.data.return) {
							tripItemObj.data.flights = tripItemObj.data.flights.concat(tripItemObj.data.return.flightsArray);
						}
					}

					// Testing
					// tripItemObj.bookingStatus.code = "unknown"; // Mark as unknown booked status, so that it will still try to fetch unbooked flight data as a fallback;
					// tripItemObj.bookingStatus.text = "Unknown";
					// tripItemObj.alerts.other.push({
					// 	alert_code: "red",
					// 	alert_type: "issue",
					// 	priority: 100, // Highest priority - no other alerts are relevant if this is the case
					// 	main_message: "Something went wrong",
					// 	description: `Our team is looking into this and will be in touch shortly. Please keep an eye on your emails. If you keep having issues, please contact support for further assistance using the booking reference number ${ tripID }${tripItemID ? '_' + tripItemID : ''} at ${constants.supportEmailLink()}`,
					// 	secondary_message: "Please be patient while someone investigates this issue. We will be in touch shortly.",
					// });

					commit('SET_TRIP_ITEM', { tripID: tripID, tripItemID: tripItemObj.id, tripItemData: tripItemObj });
					
					// Fetch Trip Item travellers
					await dispatch('fetchFlightTripItemTravellers', { tripID, tripItemID: tripItemObj.id, tripItemData: tripItems[i] });

				} else if(tripItemObj.type == "tour") {
					let getTourResponse = false;
					let getTourDatesResponse = false;
					let getTourDepartureResponse = false;

					[ getTourResponse, getTourDepartureResponse, getTourDatesResponse ] = await Promise.all([state.tripItemPromises[tripItem.id].getTourPromise, state.tripItemPromises[tripItem.id].getTourDeparturePromise, state.tripItemPromises[tripItem.id].getTourDatesPromise])

					tripItemObj.data = { // Data property is specific to tripItem TYPE - e.g. flights / hotels / tours
						tourID: tripItem.data.tour_id,
						departureID: tripItem.data.departure_id,
						roomCode: tripItem.data.room_code,
						fareKey: tripItem.data.fare_key,
					}

					if(getTourResponse && getTourDepartureResponse) {
						tripItemObj.data.length = getTourResponse.data.data.itinerary.data.duration;
						tripItemObj.data.mealsIncluded = getTourResponse.data.data.itinerary.data.meals_included;
						tripItemObj.data.images = getTourResponse.data.data.images;
						tripItemObj.data.startLocation = getTourResponse.data.data.itinerary.data.start_location;
						tripItemObj.data.endLocation = getTourResponse.data.data.itinerary.data.end_location ? getTourResponse.data.data.itinerary.data.end_location : '';
						tripItemObj.data.rawTourData = getTourResponse.data.data;
						tripItemObj.name = getTourResponse.data.data.name;
						tripItemObj.data.dates = getTourDatesResponse.data.data;
						tripItemObj.data.nearestAirport = {};
						tripItemObj.data.nearestAirport.start = getTourDepartureResponse.data.data.nearest_start_airport.code;
						tripItemObj.data.nearestAirport.end = getTourDepartureResponse.data.data.nearest_start_airport.code;
					}

					// Booked only data
					if(tripItemObj.bookingStatus.code == "booked") {
						let bookedTourDetails = await state.tripItemPromises[tripItem.id].bookedTourDetails;
						if(!bookedTourDetails) {
							tripItemObj.bookingStatus.code = "unknown"; // Mark as unknown booked status, so that it will still try to fetch unbooked flight data as a fallback;
							tripItemObj.bookingStatus.text = "Unknown";
							tripItemObj.alerts.other.push({
								alert_code: "red",
								alert_type: "issue",
								priority: 100, // Highest priority - no other alerts are relevant if this is the case
								main_message: "Something went wrong",
								description: `Our team is looking into this and will be in touch shortly. Please keep an eye on your emails. If you keep having issues, please contact support for further assistance using the booking reference number ${ tripID }${tripItemID ? '_' + tripItemID : ''} at ${constants.supportEmailLink()}`,
								secondary_message: "Please be patient while someone investigates this issue. We will be in touch shortly.",
							});
						}

						tripItemObj.price = bookedTourDetails.data.data[tripItem.id].price;

						tripItemObj.bookingStatus.gapiBookingID = bookedTourDetails.data.data[tripItem.id].gapi_booking_id;
						tripItemObj.bookingStatus.gapiDepartureServiceID = bookedTourDetails.data.data[tripItem.id].gapi_departure_service_id;
						tripItemObj.data.startDate = bookedTourDetails.data.data[tripItem.id].start_date;
						tripItemObj.data.finishDate = bookedTourDetails.data.data[tripItem.id].end_date;
						tripItemObj.data.room = bookedTourDetails.data.data[tripItem.id].rooms[0]; // overwrite what is stored on the trip? 
						tripItemObj.rawBookedTourDetails = bookedTourDetails.data.data[tripItem.id];
					}
					
					// Not booked only data
					if(tripItemObj.bookingStatus.code == "not_booked") 	{

						// TODO: All of this stuff here should be in its own function - so that it can be refired after selecting a new date?
							// Also add dates here, so that it doesn't do the annoying separate load for date data.
						
						// Rooms
						tripItemObj.data.room = false;
						tripItemObj.data.availability = getTourDepartureResponse.data.data.availability;
						tripItemObj.data.roomOptions = getTourDepartureResponse.data.data.rooms;
						tripItemObj.data.startDate = getTourDepartureResponse.data.data.start_date;
						tripItemObj.data.finishDate = getTourDepartureResponse.data.data.finish_date;

						// Selected room - TODO: Move this to not-booked tours only? The data could change after booking. Booked endpoint doesn't currently have any data to replace it tho
						if(tripItemObj.data.roomCode) { // If fareKey provided, determine specific fare information + Pax requirement information
							tripItemObj.data.room = tripItemObj.data.roomOptions.find(item=>item.code == tripItemObj.data.roomCode);
						}
						// Booking Deadline Approaching
						let now = new Date();
						let departure = new Date(tripItemObj.data.startDate); // Is the departure the last booking moment? Or another moment (likely)?
						let difference = departure.getTime() - now.getTime();
						let daysDifference = Math.floor(difference / (1000 * 3600 * 24));
						if(daysDifference < 0) {
							tripItemObj.alerts.datesValidity.push({
								alert_code: "red",
								alert_type: "expiry",
								priority: 80, 
								main_message: "Tour departure passed",
								secondary_message: "Tour departure passed",
								description: "The selected tour is in the past. Please select a new departure date, or remove the tour from the trip",
								data: { // Specific data for this alert_type
									reason: "Tour date has passed",
								}
							});
							error = "Tour departure passed. Please select a new departure date."
						} else if(daysDifference < 14) {
							let main_message = "Last " + daysDifference + " days for booking";
							let description = "Your tour starts in " + daysDifference + " days. Please make your booking to secure your place."
							if(daysDifference <= 1 && daysDifference >= 0) {
								main_message = "Last day for booking";
								description = "Your flight is tomorrow. Please make your booking to secure this flight."
								if(daysDifference == 0) {
									description = "Your flight is today. Please make your booking to secure this flight."
								}
							}

							tripItemObj.alerts.warning.push({
								alert_code: "blue",
								alert_type: "deadline",
								priority: 10, // Lowest priority - every other alert is probably more important
								main_message: main_message,
								secondary_message: "Booking is waiting",
								description: description,
								data: {
									days: daysDifference,
								}
							});
						}
					}

					// Data for both booked and not booked
					tripItemObj.date = new Date(tripItemObj.data.startDate);
					// Start and end dates for the trip.
					if(tripItemObj.data.startDate < tripDates.start || !tripDates.start) {
						tripDates.start = tripItemObj.data.startDate;
					}
					if(tripItemObj.data.finishDate > tripDates.end || !tripDates.end) {
						tripDates.end = tripItemObj.data.finishDate;
					}

					// Testing
					// tripItemObj.bookingStatus.code = "unknown"; // Mark as unknown booked status, so that it will still try to fetch unbooked flight data as a fallback;
					// tripItemObj.bookingStatus.text = "Unknown";
					// tripItemObj.alerts.other.push({
					// 	alert_code: "red",
					// 	alert_type: "issue",
					// 	priority: 100, // Highest priority - no other alerts are relevant if this is the case
					// 	main_message: "Something went wrong",
					// 	description: `Our team is looking into this and will be in touch shortly. Please keep an eye on your emails. If you keep having issues, please contact support for further assistance using the booking reference number ${ tripID }${tripItemID ? '_' + tripItemID : ''} at ${constants.supportEmailLink()}`,
					// 	secondary_message: "Please be patient while someone investigates this issue. We will be in touch shortly.",
					// });
					// // error = "It looks like there may have been a problem with this booking. Please contact support for further assistance at " + constants.supportEmailLink() + "";

					commit('SET_TRIP_ITEM', { tripID: tripID, tripItemID: tripItemObj.id, tripItemData: tripItemObj });
					// Fetch Trip Item travellers
					await dispatch('fetchTourTripItemTravellers', { tripID, tripItemID: tripItemObj.id, tripItemData: tripItems[i], getTourResponse, booked: tripItemObj.bookingStatus.code == "booked"});
				}

				commit('SET_ALL_ALERTS', { tripItemID: tripItem.id });

			};

			try {
				if(unbookedFlightIDs.length > 0) {
					await dispatch('checkFlightsValidity', { tripID, tripItemIDs: unbookedFlightIDs });
				}
			} catch (error) {
				Vue.prototype.$rollbar.error(error);
				console.error(error);
			}

			// Wipe all state promises - to ensure the next time it is needed, it will be fetched again rather than using old data.
			state.tripItemPromises = {};
			state.tripPromises = {};

			// Fetch Trip + user travellers (without specific trip data)
			await dispatch('fetchTripTravellers', { tripID: tripID });
			await dispatch('fetchUserTravellers', { });

			commit('SET_TRIP_DATES', { tripDates: tripDates });
			commit('SET_BOOKING_VALIDATION', { totalPaid: totalPaid });

			commit('SET_LOADING', false);
			console.log(state);

			return Promise.resolve(true);
		} catch (error) {
			Vue.prototype.$rollbar.error(error);
			dispatch('handleError', { error: error });
			return Promise.resolve(true);
		}
	},

	/**
	 * Updates the fare selected fare for a trip item in the backend. Updates state with result.
	 * @param {num} tripID the tripID for the trip
	 * @param {num} tripItemID the tripItemID. 
	 * @param {num} fareKey the selected fare key. 
	 */
	 async updateFare({ state, commit, dispatch }, { tripID, tripItemID, fareKey, price }) {
		// Fetch new fare data from fare options that we already have in state
		let fareSelection = state.tripItems[tripItemID].data.flightsDetails.fares.filter(obj => {
			return obj.key == fareKey;
		});
		let fareData = JSON.parse(JSON.stringify(fareSelection[0]));
		
		try {
			track.event('selectFare', {
				itemType: 'flight',
				fareName: fareData.name,
				ecommerce: {
					value: fareData.price.amount,
					currency: fareData.price.currency,
				},
			});
		} catch(e) {
			console.error(e);
			this.$rollbar.error("Tracking: " + e);
		}

		return tripService.modifyTripItem(tripID, tripItemID, { fare: fareKey, price: price })
			.then(async response => {
				// UPDATE STATE, Without requiring pinging the backend
				
				
				// Set the state fare data to the new selected fare
				commit('SET_FARE', { tripItemID: tripItemID, fare: fareData, fareKey: fareKey})
				commit('SET_PRICE', { tripItemID: tripItemID, price: price })

				if(tripItemID) {
					let type = state.tripItems[tripItemID].type;
					await dispatch('fetchTripItemTravellers', { tripID, tripItemID, type });
				}
				
				await dispatch('checkFlightsValidity', { tripID, tripItemIDs: [tripItemID] }); // Update validity state

				return Promise.resolve(true);
			},
			error => {
				return Promise.reject(error);
			});
	},

	/**
	 * Updates the fare selected fare for a trip item in the backend. Updates state with result.
	 * @param {num} tripID the tripID for the trip
	 * @param {num} tripItemID the tripItemID. 
	 * @param {num} fareKey the selected fare key. 
	 */
	async updateTourDeparture({ state, commit, dispatch }, { tripID, tripItemID, tourID, departureID, roomCode, fareKey }) {
		return tripService.modifyTripItem(tripID, tripItemID, { departure_id: departureID, room_code: roomCode, fare_key: fareKey })
			.then(async response => {
				// UPDATE STATE, Without re-firing the initTrip funtcion.
				// Update rawTourDepartureData
				if(state.tripItems[tripItemID].data.departureID != departureID) {
					let getTourDepartureResponse = await searchService.getTourDeparture(tourID, departureID);
					commit('SET_TOUR_DEPARTURE', { tripItemID: tripItemID, tourDeparture: getTourDepartureResponse.data.data });
				}
				
				// Fetch new room data from room options that we already have in state
				let roomSelection = state.tripItems[tripItemID].data.roomOptions.filter(obj => {
					return obj.code == roomCode;
				});
				// Set the state room data to the new selected room
				let roomData = roomSelection[0];
				commit('SET_ROOM', { tripItemID: tripItemID, room: roomData, roomCode: roomCode })
				
				await dispatch('fetchTourTripItemTravellers', { tripID, tripItemID }); // Update pricing information via this
				return Promise.resolve(true);
			},
			error => {
				return Promise.reject(error);
			});
	},

	/**
	 * Updates the fare selected fare for a trip item in the backend. Updates state with result.
	 * @param {num} tripID the tripID for the trip
	 * @param {num} tripItemID the tripItemID. 
	 * @param {num} fareKey the selected fare key. 
	 */
	 async updateTripItem({ state, commit, dispatch }, { tripID, tripItemID, data }) {
		return tripService.modifyTripItem(tripID, tripItemID, data)
			.then(async response => {
				// TODO: UPDATE STATE, Without requiring pinging the backend?
				await dispatch('getTripData', { tripID, tripItemID }); // This also handles user pax fetching

				return Promise.resolve(true);
			},
			error => {
				return Promise.reject(error);
			});
	},

	/**
	 * Saves an new traveller, and if IDs provided, adds to a trip and/or trip item (or none). Updates state with result.
	 * @param {num} tripID the tripID for the trip
	 * @param {num} tripItemID the tripItemID. 
	 * @param {json} paxData the data for the passenger. 
	 */
	 async saveNewTraveller({ state, commit, dispatch }, { tripID, tripItemID, paxData }) { 
		commit('SET_TRAVELLERS_LOADING', true);
		return travellerService.saveTraveller(false, tripID, tripItemID, paxData)
			.then(async response => {
				// BUG: For some reason, after adding a new traveller (and on the manageTripItemTravellers page - calcTravellers is re-called) - then toggling a traveller (reCalcTravellers is called) - the new traveller is 'undefined' in the this.travellers array. I can't figure out what is happening . It is fine after the calcTravellers call, but when reCalc is called, it is undefined. No idea.
				// For now, just force full refresh by setting tripID to false.
				commit('SET_TRIP_ID', false);

				// Update State
				// if(tripItemID) {
				// 	let type = state.tripItems[tripItemID].type;
				// 	await dispatch('fetchTripItemTravellers', { tripID, tripItemID, type });
				// } 
				// if(tripID) {
				// 	await dispatch('fetchTripTravellers', { tripID });
				// 	// await dispatch('fetchUserTravellers', { getFlightsResponse: getFlightsResponse });
				// } else {
				// 	// If no tripID provided here, then trip specific travellers might be out of date (pax could have been removed from TRIP, and trip ITEM doesn't know)
				// 	// Set trip item ID to false - to force refresh and ensure that this data is fresh when loading a trip item.
				// 	commit('SET_TRIP_ID', false);
				// }
				
				commit('SET_TRAVELLERS_LOADING', false);
				return Promise.resolve(true);
			},
			error => {
				commit('SET_TRAVELLERS_LOADING', false);
				return Promise.reject(error);
			}
		);
	},

	/**
	 * Saves an existing traveller to a trip item and/or a trip. Updates state with result.
	 * @param {num} paxID the selected traveller by ID
	 * @param {num} tripID the tripID for the trip
	 * @param {num} tripItemID the tripItemID. 
	 */
	async addExistingTravellerToTrip({ state, commit, dispatch }, { paxID, tripID, tripItemID }) { 
		commit('SET_TRAVELLERS_LOADING', true);
		return travellerService.saveTraveller(paxID, tripID, tripItemID, false)
			.then(async response => {
				// Update State
				if(tripItemID) {
					let type = state.tripItems[tripItemID].type;
					await dispatch('fetchTripItemTravellers', { tripID, tripItemID, type });
				}
				await dispatch('fetchTripTravellers', { tripID });
				// await dispatch('fetchUserTravellers', { getFlightsResponse: getFlightsResponse });
				
				commit('SET_TRAVELLERS_LOADING', false);
				return Promise.resolve(true);
			},
			error => {
				commit('SET_TRAVELLERS_LOADING', false);
				return Promise.reject(error);
			}
		);
	},

	/**
	 * Edit a travellers data - currently updates across all areas (user, trip, tripItem) - work to be done here in future. Updates state with result.
	 * @param {num} travellerID the selected traveller by ID
	 * @param {num} paxData the data for the passenger
	 * @param {num} tripID the tripID (only used to ensure appropriate state is updated, based on where it was called from)
	 * @param {num} tripItemID the tripItemID (only used to ensure appropriate state is updated, based on where it was called from)
	 */
	 async editTraveller({ state, commit, dispatch }, { travellerID, paxData, tripID = false, tripItemID = false }) { 
		// TODO: how will we handle different levels of traveller data - e.g. trip item specific override data?
		commit('SET_TRAVELLERS_LOADING', true);
		return travellerService.editGuestTraveller(travellerID, paxData)
			.then(async response => {
				// For each trip item, update the traveller data
				let promises = [];
				for(let tripItemID in state.tripItems) {
					let type = state.tripItems[tripItemID].type;
					let promise = dispatch('fetchTripItemTravellers', { tripID, tripItemID, type });
					promises.push(promise);
				}
				let promise = dispatch('fetchTripTravellers', { tripID });
				promises.push(promise);
				await Promise.all(promises);

				commit('SET_TRAVELLERS_LOADING', false);
				return Promise.resolve(true);
			},
			error => {
				commit('SET_TRAVELLERS_LOADING', false);
				return Promise.reject(error);
			}
		);
	},

	/**
	 * Removes a traveller from a trip item and/or a trip. Updates state with result.
	 * @param {num} paxID the selected traveller by ID
	 * @param {num} tripID the tripID for the trip
	 * @param {num} tripItemID the tripItemID. 
	 */
	 async removeTravellerFromTrip({ state, commit, dispatch }, { paxID, tripID, tripItemID }) { 
		commit('SET_TRAVELLERS_LOADING', true);
		return travellerService.removeTraveller(paxID, tripID, tripItemID)
			.then(async response => {
				// Update State
				if(tripItemID) {
					let type = state.tripItems[tripItemID].type;
					await dispatch('fetchTripItemTravellers', { tripID, tripItemID, type });
					await dispatch('fetchTripTravellers', { tripID });
				} else {
					// If no tripItemID provided here, then trip item specific travellers might be out of date (pax could have been removed from TRIP, and trip ITEMs don't know)
					// Set trip ID to false - and force refresh and ensure that this data is fresh when loading a trip item.
					dispatch('initTrip', { tripID: tripID, force: true }); // Temp? Fetch entire trip again to ensure every Trip Item has appropriate passengers

					// This will of course also refresh trip item data (flights etc) - can we only force refresh for travellers?
				}

				commit('SET_TRAVELLERS_LOADING', false);
				return Promise.resolve(true);
			},
			error => {
				commit('SET_TRAVELLERS_LOADING', false);
				return Promise.reject(error);
			}
		);
	},

	/**
	 * Adds a related traveller for a specified infant traveller on a trip item. Updates state with result.
	 * @param {num} paxID the selected traveller by ID
	 * @param {num} tripID the tripID for the trip
	 * @param {num} tripItemID the tripItemID. 
	 */
	async addRelatedTravellerToInfant({ state, commit, dispatch }, { travellerID, tripID, tripItemID, relatedTravellerID }) { 
		commit('SET_TRAVELLERS_LOADING', true);
		return travellerService.addRelatedTraveller(travellerID, tripID, tripItemID, relatedTravellerID)
			.then(async response => {
				// Update the travellers with the new related passenger ID
				let traveller = state.tripItems[tripItemID].travellers.find(traveller => traveller.traveller_id === travellerID);
				if(traveller) {
					traveller.related_passenger_id = relatedTravellerID;
				}
				let relatedTraveller = state.tripItems[tripItemID].travellers.find(traveller => traveller.traveller_id === relatedTravellerID);
				if(relatedTraveller) {
					relatedTraveller.related_infant_id = travellerID;
				}

				if(state.tripItems[tripItemID].bookingStatus.code !== 'booked') {
					dispatch('validateTripItemTravellers', { tripItemID }); // Re-validate the trip item travellers if not booked (this should never be called when booked anyway)
				}

				commit('SET_TRAVELLERS_LOADING', false);
				return Promise.resolve(true);
			},
			error => {
				commit('SET_TRAVELLERS_LOADING', false);
				return Promise.reject(error);
			}
		);
	},

	/**
	 * Uploads an image for a trip. Updates state with result.
	 * @param {num} tripID  the tripID for the trip
	 * @param {other} Image the raw image data
	 */
	async updateTripHeader({ state, commit, dispatch }, { tripID, image, name }) { 
		commit('SET_LOADING', true);
		return tripService.editTrip(tripID, { tripName: name, tripImage: image })
			.then(async response => {
				//Update relevant state
				await dispatch('fetchTripSpecificData', { tripID });

				commit('SET_LOADING', false);
				return Promise.resolve(true);
			},
			error => {
				commit('SET_LOADING', false);
				return Promise.reject(error);
			})
	},

	/**
	 * Remove a trip item from a trip
	 * @param {num} tripID  the tripID for the trip
	 * @param {num} tripItemID  the tripItemID for the trip
	 */
	async removeTripItem({ state, commit, dispatch }, { tripID, tripItemID }) { 
		commit('SET_LOADING', true);
		return tripService.removeTripItem(tripID, tripItemID)
			.then(async response => {
			
				// Delete the trip item from the trip state
				// delete state.tripItems[tripItemID];
				Vue.delete(state.tripItems, tripItemID); // Needed to make sure Vue re-renders

				commit('SET_LOADING', false);
				return Promise.resolve(true);
			},
			error => {
				commit('SET_LOADING', false);
				return Promise.reject(error);
			})
	},
	

	//
	// STATE REFRESH METHODS - used to fetch + keep state up to date after actions
	//

	/**
	 * Fetch and store the travellers at the user level. If there is tour/flight data in the state, it will utilise this to calculate the age types based on the trip item state.
	 */
	async fetchUserTravellers({ state, commit }, { }) {
		if(!state.tripPromises.userTravellersPromise) {
			state.tripPromises.userTravellersPromise = travellerService.fetchUserTravellers();
		}

		let travellersResponse = await state.tripPromises.userTravellersPromise;

		let tourData = false;
		let flightsDetails = false;

		if(state.tripItemID && state.tripItems[state.tripItemID].data) {
			if(state.tripItems[state.tripItemID].data.flightsDetails) {
				// If there is flight data for age type calculations, store it
				flightsDetails = state.tripItems[state.tripItemID].data.flightsDetails;
			}
		}

		if(state.tripItemID && state.tripItems[state.tripItemID].type == "tour") {
			tourData =  state.tripItems[state.tripItemID];
		}

		let travellers = [];
		Object.keys(travellersResponse.data.data).forEach((index) => { // convert travellers to array
			let traveller = travellersResponse.data.data[index]; 
			traveller.traveller_id = parseInt(traveller.id);
			traveller.data = JSON.parse(traveller.data);
			
			if(tourData && tourData.data.room) { // Calculate age types based on tour dates
				let minDobLimit = {};
				let maxDobLimit = {};
				let hasFound = false;
				for(let i in tourData.data.room.price_bands) {
					let band = tourData.data.room.price_bands[i];
					let type = band.name;

					minDobLimit[type] = new Date(tourData.data.startDate);
					maxDobLimit[type] = new Date(tourData.data.startDate);

					let paxDob = new Date(traveller.data.dob).valueOf();
					minDobLimit[type] = minDobLimit[type].setFullYear(minDobLimit[type].getFullYear() - band.max_age).valueOf();
					maxDobLimit[type] = maxDobLimit[type].setFullYear(maxDobLimit[type].getFullYear() - band.min_age).valueOf();
					
					if(paxDob && paxDob < maxDobLimit[type] && paxDob > minDobLimit[type]) { // Check if valid dob
						if(paxDob < maxDobLimit[type] && paxDob > minDobLimit[type]) {
							traveller.type = type;
							
							hasFound = true;
							continue; // Break from loop - has found a price band for this traveller
						}
					}
				}
				if(!hasFound) {
					// Has not found a valid age type - mark invalid
					traveller.type = 'Invalid';
				}

			} else if(flightsDetails) { // Calculate age types based on flight dates
				// Determine traveller type
				let adultDobLimit = new Date(flightsDetails.departure_display);
				let infantDobLimit = new Date(flightsDetails.departure_display);
				let paxDob = new Date(traveller.data.dob).valueOf();
				adultDobLimit = adultDobLimit.setFullYear(adultDobLimit.getFullYear() - constants.PAX_ADULT_MIN_AGE).valueOf();
				infantDobLimit = infantDobLimit.setFullYear(infantDobLimit.getFullYear() - constants.PAX_INFANT_MAX_AGE).valueOf();

				if(paxDob) { // Check if valid dob
					if(paxDob < adultDobLimit) {
						traveller.type = "Adult";
					} else if(paxDob > adultDobLimit && paxDob < infantDobLimit) {
						traveller.type = "Child";
					} else {
						traveller.type = "Infant";
					}
				} else {
					// Not valid Dob, or no Dob
					traveller.type = "Invalid";
				}
			}

			
			travellers.push(traveller);
		});

		state.tripPromises.userTravellersPromise = false; // Set to false at the end, so that the next call will get new data.

		commit('SET_USER_TRAVELLERS', { userTravellers: travellers });
		return Promise.resolve(true);
	},

	/**
	 * Fetch and store the travellers at the trip level. If there is tour/flight data in the state, it will utilise this to calculate age types.
	 * @param {num} tripID  the tripID for the trip
	 */
	async fetchTripTravellers({ state, commit }, { tripID }) {
		if(!state.tripPromises.tripTravellersPromise) {
			state.tripPromises.tripTravellersPromise = travellerService.fetchTripTravellers(tripID);
		}

		let travellersResponse = await state.tripPromises.tripTravellersPromise;
		
		let flightsDetails = false;
		let tourData = false;

		if(state.tripItemID && state.tripItems[state.tripItemID].data) {
			if(state.tripItems[state.tripItemID].data.flightsDetails) {
				// If there is flight data for age type calculations, store it
				flightsDetails = state.tripItems[state.tripItemID].data.flightsDetails;
			}
		}
		if(state.tripItemID && state.tripItems[state.tripItemID].type == "tour") {
			tourData =  state.tripItems[state.tripItemID];
		}
		
		let totalTravellers =  { Adult: 0, Child: 0, Infant: 0, Invalid: 0 };
		let travellers = [];
		Object.keys(travellersResponse.data.data).forEach((index) => { // convert travellers to array
			let traveller = travellersResponse.data.data[index]; 
			traveller.traveller_id = parseInt(index);
			
			if(tourData && tourData.data.room) { // Calculate age types based on tour dates
				let minDobLimit = {};
				let maxDobLimit = {};
				let hasFound = false;
				for(let i in tourData.data.room.price_bands) {
					let band = tourData.data.room.price_bands[i];
					let type = band.name;

					minDobLimit[type] = new Date(tourData.data.startDate);
					maxDobLimit[type] = new Date(tourData.data.startDate);

					let paxDob = new Date(traveller.data.dob).valueOf();
					minDobLimit[type] = minDobLimit[type].setFullYear(minDobLimit[type].getFullYear() - band.max_age).valueOf();
					maxDobLimit[type] = maxDobLimit[type].setFullYear(maxDobLimit[type].getFullYear() - band.min_age).valueOf();
					
					if(paxDob && paxDob < maxDobLimit[type] && paxDob > minDobLimit[type]) { // Check if valid dob
						if(paxDob < maxDobLimit[type] && paxDob > minDobLimit[type]) {
							traveller.type = type;
							
							hasFound = true;
							continue; // Break from loop - has found a price band for this traveller
						}
					}
				}
				if(!hasFound) {
					// Has not found a valid age type - mark invalid
					traveller.type = 'Invalid';
				}

			} else if(flightsDetails) {
				let adultDobLimit, infantDobLimit = null;
				// Determine traveller type based on departure date
				adultDobLimit = new Date(flightsDetails.departure_display);
				infantDobLimit = new Date(flightsDetails.departure_display);
				let paxDob = new Date(traveller.data.dob).valueOf();
				adultDobLimit = adultDobLimit.setFullYear(adultDobLimit.getFullYear() - constants.PAX_ADULT_MIN_AGE).valueOf();
				infantDobLimit = infantDobLimit.setFullYear(infantDobLimit.getFullYear() - constants.PAX_INFANT_MAX_AGE).valueOf();

				if(paxDob) { // Check if valid dob
					if(paxDob < adultDobLimit) {
						traveller.type = "Adult";
						totalTravellers.Adult += 1;
					} else if(paxDob > adultDobLimit && paxDob < infantDobLimit) {
						traveller.type = "Child";
						totalTravellers.Child += 1;
					} else {
						traveller.type = "Infant";
						totalTravellers.Infant += 1;
					}
				} else {
					// Not valid Dob, or no Dob
					traveller.type = "Invalid";
					totalTravellers.Invalid += 1;
				}

			}
			
			travellers.push(traveller);
		});

		state.tripPromises.tripTravellersPromise = false; // Set to false at the end, so that the next call will get new data.

		commit('SET_TRIP_TRAVELLERS', { tripID: tripID, tripTravellers: travellers, totalTravellers: totalTravellers });
		return Promise.resolve(true);
	},

	/**
	 * Calls the appropriate trip-item specific traveller fetch function based on the trip item type.
	 * @param {num} tripID  the tripID for the trip
	 * @param {num} tripItemID  the tripItemID for the trip
	 * @param {num} type  the type of trip item (flight or tour etc)
	 */
	async fetchTripItemTravellers({ dispatch, state }, { tripID, tripItemID, type }) {
		if(type == "flight") {
			await dispatch('fetchFlightTripItemTravellers', { tripID, tripItemID });
		} else {
			await dispatch('fetchTourTripItemTravellers', { tripID, tripItemID });
		}

		return Promise.resolve(true);
	},

	/**
	 * Fetch and store the travellers at the trip level. Calculates the age types and traveller requirements based on the trip item state.
	 * @param {num} tripID  the tripID for the trip
	 * @param {num} tripItemID  the tripItemID for the trip
	 * @param {num} tripItemData the tripItemData to use for the age type and requirements calculations. If not provided, will fetch from the backend
	 */
	async fetchFlightTripItemTravellers({ state, commit, dispatch }, { tripID, tripItemID, tripItemData = false }) {
		if(!tripItemData) {
			let tripItemResponse = await tripService.fetchTripItems(tripID, tripItemID);
			tripItemData = tripItemResponse.data.data; // This seems to sometimes be an array and sometimes not (even when just one result). If this needs changing to [0] again, then backend needs to fix this to be consistent.
		}
		let tripItem = tripItemData.trip_item;
		let flightsDetails = state.tripItems[tripItemID].data.flightsDetails;

		let fare = false;

		let travellers = false;

		let totalTravellers =  { Adult: 0, Child: 0, Infant: 0, Invalid: 0 };
		let requiredTravellers =  { Adult: 0, Child: 0, Infant: 0 };
		let totalRequiredTravellers = { Adult: 0, Child: 0, Infant: 0 };

		let travellerTypePricing = {};
		if(state.tripItems[tripItemID].bookingStatus.code === 'booked') {
			// Travellers are taken from booked flights endpoint specifically - because the trip item data could be wrong (if the user updates pax details via airline etc)
			travellers = state.tripItems[tripItemID].rawBookedFlightDetails.travelers.map((item, index) => {
				let type = false;
				let traveller = {
					traveller_id: item.travelerId,
					type: type,
					data: {
						countryOfIssue: false, // Not provided
						dob: false, // Not provided
						email: item.contact ? item.contact.emailAddress : '',
						firstName: item.name.firstName,
						lastName: item.name.lastName,
						gender: item.gender,
						phone: false, // Provided, but complicated - includes country code etc - as an array. Leaving for now.
						passportExpiry: false, // Not provided
						passportNumber: false, // Not provided
					}
				}
				return traveller;
			});
		} else { // Not booked (or unknown etc)
			travellers = tripItemData.pax; // Travellers are taken from the trip item data for not_booked flights
			if(flightsDetails && tripItem.data.fare) { // If fareKey provided, determine specific fare information + Pax requirement information
				// Determine required pax data from fares
				if(Array.isArray(flightsDetails.fares)) {
					fare = flightsDetails.fares.find(item=>item.key == tripItem.data.fare); // Try to select a specific fare
					if(!fare) {
						fare = flightsDetails.fares[0]; // Default to first fare
					}
					if(fare) {
						let requiredPaxInfo = fare.pax_qty; // Pax requirements from actual selected fare
						
						for(let paxType in requiredPaxInfo) {
							if(paxType == "adults") {
								requiredTravellers.Adult += Number(requiredPaxInfo[paxType]);
							}
							if(paxType == "children") {
								requiredTravellers.Child += Number(requiredPaxInfo[paxType]);
							}
							if(paxType == "infants") {
								requiredTravellers.Infant += Number(requiredPaxInfo[paxType]);
							}
						}
						
					}

					let paxPriceInfo = flightsDetails.fares[0].price.pax; // Default from first fare
					if(fare) {
						paxPriceInfo = fare.price.pax; // Actual selected fare
					}
					Object.entries(paxPriceInfo).forEach((pax) => {
						let type = pax[0];
						type = type[0].toUpperCase() + type.substring(1); // Uppercase the first letter so that it matches all other keys
						let price = pax[1].each;
						if(!travellerTypePricing[type]) {
							travellerTypePricing[type] = {};
						}
						travellerTypePricing[type].price = price;
					});
				}

				totalRequiredTravellers = JSON.parse(JSON.stringify(requiredTravellers)); // CLONE rather than assign by reference
				console.log(travellers);
				if(travellers && travellers.length > 0) {
					travellers.forEach((traveller) => {
						if(traveller.data != "" && traveller.data != null) {
							// traveller.data = traveller.data;
							traveller.traveller_id = traveller.passenger_id;

							let adultDobLimit = new Date(flightsDetails.departure_display);
							let infantDobLimit = new Date(flightsDetails.departure_display);
							let paxDob = new Date(traveller.data.dob).valueOf();
							adultDobLimit = adultDobLimit.setFullYear(adultDobLimit.getFullYear() - constants.PAX_ADULT_MIN_AGE).valueOf();
							infantDobLimit = infantDobLimit.setFullYear(infantDobLimit.getFullYear() - constants.PAX_INFANT_MAX_AGE).valueOf();

							if(paxDob) { // Check if valid dob
								traveller.valid = true;
								if(paxDob < adultDobLimit) {
									traveller.type = "Adult";
									requiredTravellers.Adult -= 1;
									totalTravellers.Adult += 1;
								} else if(paxDob > adultDobLimit && paxDob < infantDobLimit) {
									traveller.type = "Child";
									requiredTravellers.Child -= 1;
									totalTravellers.Child += 1;
								} else {
									traveller.type = "Infant";
									requiredTravellers.Infant -= 1;
									totalTravellers.Infant += 1;
	
									
								}
							} else {
								// Not valid Dob, or no Dob
								traveller.type = "Invalid";
								traveller.valid = false;
								totalTravellers.Invalid += 1;
							}

							// assign the related_infant_id to the related traveller
							if(traveller.type == "Infant" && traveller.related_passenger_id && traveller.related_passenger_id != 0) {
								// Get the traveller object for the related passenger from the array based on the traveller_id
								let relatedTraveller = travellers.find(item=>item.traveller_id == traveller.related_passenger_id);
								if(relatedTraveller) {
									relatedTraveller.related_infant_id = traveller.passenger_id;
								}
							}
							
						}
					});
				}
			}
		}

		commit('SET_TRIP_ITEM_TRAVELLERS', { tripItemID, travellers: travellers });
		commit('SET_TRIP_ITEM_TRAVELLERS_OTHER_DATA', { tripItemID, requiredTravellers: requiredTravellers, totalRequiredTravellers: totalRequiredTravellers, totalTravellers: totalTravellers, travellerTypePricing: travellerTypePricing  });

		if(state.tripItems[tripItemID].bookingStatus.code !== 'booked') {
			dispatch('validateTripItemTravellers', { tripItemID });
		}

		// Set any infants to have an adult associated with them by default
		// Loop through travellers and check if they are an infant
		for(let i in travellers) {
			let traveller = travellers[i];
			if(traveller.type == "Infant") {
				// Check if there is an adult associated with this infant
				if(!traveller.related_passenger_id || traveller.related_passenger_id === 0) {
					// No adult associated - find the first adult and associate them
					for(let j in travellers) {
						let adultTraveller = travellers[j];
						if(adultTraveller.type == "Adult") {
							// Check that they don't already have an infant associated with them
							if(adultTraveller.related_infant_id && adultTraveller.related_infant_id !== 0) {
								continue;
							} else {
								// Found an adult - associate them with the infant
								await dispatch('addRelatedTravellerToInfant', { tripID, tripItemID, travellerID: traveller.traveller_id, relatedTravellerID: adultTraveller.traveller_id });
								break;
							}
						}
					}
				}
			}
		}

		return Promise.resolve(true);
	},

	validateTripItemTravellers({ state, commit, dispatch }, { tripItemID }) {
		let flightsDetails = state.tripItems[tripItemID].data.flightsDetails;

		let requiredTravellers = state.tripItems[tripItemID].requiredTravellers;
		let totalRequiredTravellers = state.tripItems[tripItemID].totalRequiredTravellers;
		let travellers = state.tripItems[tripItemID].travellers;

		let travellerQtyError = false;
		let travellerDetailsError = false;

		// Traveller validation
		for(let i in travellers) {
			let traveller = travellers[i];
			// Ensure passport expiries are after last flight arrival
			let passportExpiry = new Date(traveller.data.passportExpiry);
			let mustBeValidAt = new Date(flightsDetails.arrival_display); // Arrival for outbound flight
			if(flightsDetails.return_flight) { // Use return flight instead if applicable
				mustBeValidAt = new Date(flightsDetails.return_flight.arrival_display);
			}
			
			// Validate traveller data (ensure all required fields are filled)
			let validationErrors = validate.validateTraveller(traveller);
			traveller.validationErrors = validationErrors;

			// Check Passport expiry is after the travel date (outside of validateTraveller function as it requires flight data)
			if(passportExpiry.valueOf() < mustBeValidAt.valueOf()) {
				traveller.validationErrors.expiry = ["Passport must be valid for travel"];
			}
			if(traveller.type == "Infant" && !traveller.related_passenger_id) { // Infants with no related traveller cause validation error
				traveller.validationErrors.other = ["You must select an associated adult for this infant"];
				travellerDetailsError = "Please ensure all infants have an associated Adult selected"
			}

			let validationErrorCount = Object.keys(validationErrors).reduce((acc, key) => acc.concat(validationErrors[key]), []).length;
			if(validationErrorCount > 0) {
				travellerDetailsError = "Please ensure all required fields are filled";
			}
		}

		// Booking Validation
		let travellerMainMessage = "";
		// If required travellers is > 1 : There are still travellers to add to the booking
		if(requiredTravellers.Adult > 0 || requiredTravellers.Child > 0 || requiredTravellers.Infant > 0) {
			travellerQtyError = "Please add all required travellers.";
			travellerMainMessage = "Travellers missing";
		}
		// If required travellers is negative : There are too many travellers of that type.
		if(requiredTravellers.Adult < 0 || requiredTravellers.Child < 0 || requiredTravellers.Infant < 0) {
			travellerQtyError = "Please ensure that you add the correct number of travellers. We expect a total of ";
			travellerQtyError += totalRequiredTravellers.Adult + " Adult(s), " + totalRequiredTravellers.Child + " Children and " + totalRequiredTravellers.Infant + " Infant(s).";
			travellerMainMessage = "Traveller quantity issue";
		}	

		commit('SET_TRIP_ITEM_TRAVELLER_ALERT', { tripItemID: tripItemID, alert: false }) // Wipe existing alerts first
		if(travellerQtyError) {
			let travellerAlert = {
				alert_code: "blue",
				alert_type: "traveller_validation",
				priority: 50,
				main_message: travellerMainMessage,
				secondary_message: "Incorrect number of travellers added",
				description: travellerQtyError,
				data: { // Specific data for this alert_type
					totalRequiredTravellers: totalRequiredTravellers,
					requiredTravellers: requiredTravellers
				}
			}
			commit('SET_TRIP_ITEM_TRAVELLER_ALERT', { tripItemID: tripItemID, alert: travellerAlert }); // Add the alert if there is one.
		} 
		if(travellerDetailsError) {
			let travellerAlert = {
				alert_code: "blue",
				alert_type: "traveller_validation",
				priority: 50,
				main_message: "Traveller details required",
				secondary_message: "Please enter and check all details for the required passengers",
				description: travellerDetailsError,
				data: { // Specific data for this alert_type
				}
			}
			commit('SET_TRIP_ITEM_TRAVELLER_ALERT', { tripItemID: tripItemID, alert: travellerAlert }); // Add the alert if there is one.
		} 
		commit('SET_ALL_ALERTS', { tripItemID: tripItemID }); // Update the "all" array of alerts
		commit('SET_TRIP_ITEM_TRAVELLERS', { tripItemID: tripItemID, travellers: travellers }); // Update the travellers with any validation  
	},

	/**
	 * Fetch and store the travellers at the trip level. Calculates the age types and traveller requirements based on the trip item state.
	 * @param {num} tripID  the tripID for the trip
	 * @param {num} tripItemID  the tripItemID for the trip
	 * @param {num} tripItemData the tripItemData to use for the age type and requirements calculations. If not provided, will fetch from the backend
	 */
	async fetchTourTripItemTravellers({ state, commit, dispatch }, { tripID, tripItemID, tripItemData = false, booked = false }) {
		if(!tripItemData) {
			let tripItemResponse = await tripService.fetchTripItems(tripID, tripItemID);
			tripItemData = tripItemResponse.data.data; // This seems to sometimes be an array and sometimes not (even when just one result). If this needs changing to [0] again, then backend needs to fix this to be consistent.
		}

		let tourData =  state.tripItems[tripItemID];

		let travellerAlert = false;

		let travellers = false;

		let totalTravellers =  { Adult: 0, Child: 0, Infant: 0, Invalid: 0 }; // Keys are actually dynamicly generated from the tour data - but these are the defaults. Could be others like "any".

		let travellerTypePricing = {};

		let totalPrice = 0.0;

		if(state.tripItems[tripItemID].bookingStatus.code === 'booked') {
			// Travellers are taken from booked flights endpoint specifically - because the trip item data could be wrong (if the user updates pax details via airline etc)
			travellers = state.tripItems[tripItemID].rawBookedTourDetails.customers.map((item, index) => {
				// let type = false;
				let traveller = {
					// traveller_id: item.travelerId,
					// type: type,
					data: {
						countryOfIssue: false, // Not provided
						dob: false, // Not provided
						email: false, // Not provided
						firstName: item.first_name,
						lastName: item.last_name,
						gender: false, // Not provided
						phone: false, // Not provided
						passportExpiry: false, // Not provided
						passportNumber: false, // Not provided
					}
				}

				return traveller;
			});
		} else {
			travellers = tripItemData.pax;
			if(tourData && tourData.data.room && tourData.data.room.availability) { // If room provided, determine specific room information + Pax availablility etc
				let totalAvailability = tourData.data.room.availability.total;
				let totalPax = travellers.length;
				if(totalPax > totalAvailability) {
					travellerAlert = {
						alert_code: "yellow",
						alert_type: "capacity",
						priority: 75,
						main_message: "Availability issue",
						secondary_message: "Not enough space for selected travellers",
						description: "Please lower the number of travellers, select a different room, or choose a different departure date.",
						data: { // Specific data for this alert_type
							totalAvailability: totalAvailability,
							totalTravellers: totalPax
						}
					}
				}
	
				let minAge = tourData.data.room.price_bands.reduce(function(prev, curr) {
					return prev.min_age < curr.min_age ? prev : curr;
				}).min_age;
				let maxAge = tourData.data.room.price_bands.reduce(function(prev, curr) {
					return prev.max_age > curr.max_age ? prev : curr;
				}).max_age;
	
				for(let i in tourData.data.room.price_bands) {
					let band = tourData.data.room.price_bands[i];
					let type = band.name;
	
					if(!travellerTypePricing[type]) {
						travellerTypePricing[type] = {};
					}
					travellerTypePricing[type].minAge = band.min_age;
					travellerTypePricing[type].maxAge = band.max_age;
	
					let promotions = band.prices.find(el => el.currency == "GBP").promotions;
					if(promotions.length > 0) {
						let bestPromo = promotions.reduce(function(prev, curr) {
							return prev.amount < curr.amount ? prev : curr;
						});
						travellerTypePricing[type].price = parseFloat(bestPromo.amount);
					} else {
						travellerTypePricing[type].price = parseFloat(band.prices.find(el => el.currency == "GBP").amount); // Regular price
					}
				}
	
	
				if(travellers && travellers.length > 0) {
					travellers.forEach((traveller) => {
						if(traveller.data != "" && traveller.data != null) {
							// traveller.data = traveller.data;
							traveller.traveller_id = traveller.passenger_id;
							
							let minDobLimit = {};
							let maxDobLimit = {};
							let isValidAge = false;
							for(let i in tourData.data.room.price_bands) {
								
								let band = tourData.data.room.price_bands[i];
	
								let type = band.name;
	
								minDobLimit[type] = new Date(tourData.data.startDate);
								maxDobLimit[type] = new Date(tourData.data.startDate);
	
								let paxDob = new Date(traveller.data.dob).valueOf();
								minDobLimit[type] = minDobLimit[type].setFullYear(minDobLimit[type].getFullYear() - band.max_age).valueOf();
								maxDobLimit[type] = maxDobLimit[type].setFullYear(maxDobLimit[type].getFullYear() - band.min_age).valueOf();
								
								if(paxDob < maxDobLimit[type] && paxDob > minDobLimit[type]) {
									traveller.type = type;
									traveller.valid = true;
									isValidAge = true;
	
									if(!totalTravellers[type]) {
										totalTravellers[type] = 0;
									}
									totalTravellers[type] += 1;
									
									let promotions = band.prices.find(el => el.currency == "GBP").promotions;
									if(promotions.length > 0) {
										let bestPromo = promotions.reduce(function(prev, curr) {
											return prev.amount < curr.amount ? prev : curr;
										});
										totalPrice += parseFloat(bestPromo.amount); // Promo price
									} else {
										totalPrice += parseFloat(band.prices.find(el => el.currency == "GBP").amount); // Regular price
									}
									continue; // Break from loop - has found a price band for this traveller
								}
							}
							
							// Validate traveller data (ensure all required fields are filled)
							let validationErrors = validate.validateTraveller(traveller);
							traveller.validationErrors = validationErrors;
							
							// Concatenate all validation errors into one array and get the length
							let validationErrorCount = Object.keys(validationErrors).reduce((acc, key) => acc.concat(validationErrors[key]), []).length;
							if(validationErrorCount > 0) {
								travellerAlert = {
									alert_code: "blue",
									alert_type: "traveller_validation",
									priority: 50,
									main_message: "Traveller details required",
									secondary_message: "Traveller details required",
									description: "Please enter all details for the travellers",
									data: {}
								}
							}
	
							// Ensure they are a valid age (outside of validateTraveller function as it requires tour data)
							if(!isValidAge) {
								// Alert - this traveller is not within any price band age limits
								traveller.validationErrors.dob.push("Traveller not a valid age for this tour");
	
								travellerAlert = {
									alert_code: "blue",
									alert_type: "traveller_validation",
									priority: 50,
									main_message: "Traveller does not meet age requirements",
									secondary_message: "Traveller does not meet age requirements",
									description: "Please ensure all travellers are within the accepted age range for this tour (" + minAge + " - " + maxAge + ")",
									data: { // Specific data for this alert_type
										minAge: minAge,
										maxAge: maxAge,
									}
								}
								traveller.valid = false;
								totalTravellers.Invalid += 1;
							}
						}
					});
				}
			}
	
			if(travellers.length == 0) {
				travellerAlert = {
					alert_code: "blue",
					alert_type: "traveller_validation",
					priority: 50,
					main_message: "Travellers missing",
					secondary_message: "Traveller details required",
					description: "Please add at least one traveller",
					data: { // Specific data for this alert_type
					}
				}
			}
		}

		if(travellerAlert) {
			commit('SET_TRIP_ITEM_TRAVELLER_ALERT', { tripItemID: tripItemID, alert: travellerAlert })
		} else {
			commit('SET_TRIP_ITEM_TRAVELLER_ALERT', { tripItemID: tripItemID, alert: false })
		}
		commit('SET_ALL_ALERTS', { tripItemID: tripItemID });

		commit('SET_TRIP_ITEM_TRAVELLERS', { tripItemID, travellers: travellers });
		commit('SET_TRIP_ITEM_TRAVELLERS_OTHER_DATA', { tripItemID, requiredTravellers: false, totalRequiredTravellers: false, totalTravellers: totalTravellers, travellerTypePricing: travellerTypePricing });

		// Don't set the price based on passengers if it's booked. This data is fetched elsewhere.
		if(!booked) { 
			// Update the price in both state and the backend, if the price has changed based on new passengers.
			if(totalPrice != tripItemData.trip_item.price)
			return tripService.modifyTripItem(tripID, tripItemID, { price: totalPrice })
				.then(async response => {
					commit('SET_PRICE', { tripItemID: tripItemID, price: totalPrice })

					return Promise.resolve(true);
				},
				error => {
					return Promise.reject(error);
				});
		}

		return Promise.resolve(true);
	},

	/**
 	 * Fetch and store trip level metadata for a trip (e.g. trip name and trip image)
	 * @param {num} tripID  the tripID for the trip
	 */
	async fetchTripSpecificData({ commit, dispatch }, { tripID }) {
		try {
			let tripPromise = tripService.fetchTrip(tripID);
			let tripResponse = await tripPromise;

			let tripData = tripResponse.data.data.trip_data.data;

			// Fetch Trip metadata
			if(tripData) {
				if(tripData.image) {
					commit('SET_TRIP_IMAGE', { tripID: tripID, tripImage: tripData.image });
				} else {
					commit('SET_TRIP_IMAGE', { tripID: tripID, tripImage: false });
				}
				if(tripData.name) {
					commit('SET_TRIP_NAME', { tripID: tripID, tripName: tripData.name });
				} else {
					commit('SET_TRIP_NAME', { tripID: tripID, tripName: "Your Trip!" });
				}
				
			} else {
				commit('SET_TRIP_NAME', { tripID: tripID, tripName: false });
				commit('SET_TRIP_IMAGE', { tripID: tripID, tripImage: false });
			}

			return Promise.resolve(true);
		} catch (error) {
			dispatch('handleError', { error: error });
			return Promise.reject(error);
		}
		
	},

	/**
	 * Check the validity of the flights for given tripItemIDs (array). Creates alerts based on response and stores them to state.
	 * Note that it can use an existing promise from the state for the endpoint call if it exists. Be sure to call this function with the same tripItemIDs that were used in the promise.
	 * @param {num} tripID  the tripID for the trip
	 * @param {num} tripItemIDs  an array of tripItemIDs to check
	 */
	async checkFlightsValidity({ commit, state }, { tripID, tripItemIDs }) {
		// // Call endpoint 
		if(!state.tripPromises.flightsValidityPromise) {
			state.tripPromises.flightsValidityPromise = tripService.checkFlightsValidity(tripID, tripItemIDs);
		}
		return state.tripPromises.flightsValidityPromise
			.then(response => {
				let data = {};
				let error = false;
				data.price_change = response.data.data.price_change ? response.data.data.price_change : false;
				data.flight_invalid = response.data.data.flight_invalid ? response.data.data.flight_invalid : false;
				data.no_fare = response.data.data.flight_skip ? response.data.data.flight_skip : false;
				commit('SET_FLIGHTS_VALIDITY', data);

				// Determine alerts 
				if(state.flightsValidity) { // Alerts are added in order or priority (high to low). Most important alert at the start of array (to be displayed)
					tripItemIDs.forEach(tripItemID => { // Loop through the provided IDs - not all tripItems in the state - so that we don't reset the alerts for those that aren't being rechecked.
						let tripItem = state.tripItems[tripItemID];
						tripItem.alerts.flightsValidity = []; // Reset validity alerts to blank.

						// if tripitem not booked
						if(state.flightsValidity.flight_invalid && state.flightsValidity.flight_invalid[tripItemID]) { 
							// Expired Flights
							let tripItemIDs = Object.keys(state.flightsValidity.flight_invalid);
							if(tripItemIDs.includes(tripItem.id.toString())) {
								tripItem.alerts.flightsValidity.push({
									alert_code: "red",
									alert_type: "expiry",
									priority: 100,
									main_message: "Flight cancelled",
									secondary_message: "Flight expired",
									description: "The selected flight has expired. Please select a new flight offer, or remove the flight from the trip",
									data: { // Specific data for this alert_type
										reason: state.flightsValidity.flight_invalid[tripItemID],
									}
								});
								error = "Flight expired. Please select a new flight offer, or remove the flight from the trip.";
							}
						}
						
						if(state.flightsValidity.price_change && state.flightsValidity.price_change[tripItemID] && tripItem.bookingStatus.code != "booked") {
							// Price change
							let tripItemIDs = Object.keys(state.flightsValidity.price_change);
							if(tripItemIDs.includes(tripItem.id.toString())) {
								tripItem.alerts.flightsValidity.push({
									alert_code: "yellow",
									alert_type: "price",
									priority: 60, // Should be 60. Temp change for testing. 
									main_message: "The price has changed",
									secondary_message: "The price has changed",
									description: "Price has changed. Please confirm the new price, or select a different flight.",
									data: {
										old_price: state.flightsValidity.price_change[tripItemID].old_price,
										new_price: state.flightsValidity.price_change[tripItemID].new_price,
									}
								});
								error = "The price has changed. Please confirm the new price or select a different flight.";
							}
						}
						if(state.flightsValidity.no_fare && state.flightsValidity.no_fare.includes(parseInt(tripItemID)) && tripItem.bookingStatus.code != "booked") {
						// Price change
							tripItem.alerts.flightsValidity.push({
								alert_code: "yellow",
								alert_type: "fare",
								priority: 70, 
								main_message: "No fare selected",
								secondary_message: "Fare options may have changed slightly. You must select a new fare",
								description: "Please select a fare.",
								data: {}
							});
							error = "No fare selected";
						}
						commit('SET_ALL_ALERTS', { tripItemID: tripItemID });
					});
				}
				
				state.tripPromises.flightsValidityPromise = false; // Wipe the promise, so that next time this is called it has up to date data.
				return Promise.resolve(true);
			},
			error => {
				state.tripPromises.flightsValidityPromise = false; // Wipe the promise, so that next time this is called it has up to date data.
				return Promise.reject(error);
			});

	},

	/**
	 * Confirm the price change for given tripItemID.
	 * @param {num} tripID  the tripID for the trip
	 * @param {num} tripItemID  the tripItemID for the trip
	 */
	async confirmFlightsChange({ dispatch, commit }, { tripID, tripItemID }) {
		// // Call endpoint 
		return tripService.confirmFlightsChange(tripID, tripItemID)
			.then(async response => {
				// await dispatch('checkFlightsValidity', { tripID, tripItemIDs: [tripItemID] }); // Update validity state

				// Force full refresh for all data. TODO replace with appropriate data fetching after a price change. Same as select fare?
				commit('SET_TRIP_ID', false);
				dispatch('initTrip'); // Temp? Fetch entire trip again.

				return Promise.resolve(true);
			},
			error => {
				return Promise.reject(error);
			});
	},

	/**
	 *  Fires all required endpoints for loading a trip. Stores the promises in state. The promises are used later, in the getTripData function, to map the data.
	 * @param {num} tripID  the tripID for the trip
	 * @param {num} tripItemID  the tripItemID for the trip
	 */
	async fireTripPromises({ state }, { tripID, tripItems}) {
		let unbookedFlightIDs = []; // Used to check flight validity for unbooked flights
		for(let i = 0; i < tripItems.length; i++) {
			// Object for promises
			let promises = {};

			if(tripItems[i].trip_item.type == "flight") {
				if(tripItems[i].trip_item.status == "booked") {
					try {
						promises.bookedFlightDetails = tripService.fetchBookedFlightDetails(tripID, tripItems[i].trip_item.id);
					} catch (error) {
						promises.bookedFlightDetails = false;
						Vue.prototype.$rollbar.error(error);
					}
				} else {
					unbookedFlightIDs.push(tripItems[i].trip_item.id);
				}
			}
			if(tripItems[i].trip_item.type == "tour") {
				try {
					promises.getTourPromise = searchService.getTour(tripItems[i].trip_item.data.tour_id);
					promises.getTourDatesPromise = searchService.getTourDepartures(tripItems[i].trip_item.data.tour_id);
					promises.getTourDeparturePromise = searchService.getTourDeparture(tripItems[i].trip_item.data.tour_id, tripItems[i].trip_item.data.departure_id);
				} catch (error) {
					console.error(error);
					Vue.prototype.$rollbar.error(error);
				}
				if(tripItems[i].trip_item.status == "booked") {
					try {
						promises.bookedTourDetails = tripService.fetchBookedTourDetails(tripID, tripItems[i].trip_item.id);
					} catch (error) {
						promises.bookedTourDetails = false;
						Vue.prototype.$rollbar.error(error);
					}
				}
			}
			// Add promises to the state
			state.tripItemPromises[tripItems[i].trip_item.id] = promises;
		}
	
		// Trip promises
		try {
			if(unbookedFlightIDs.length > 0) {
				// Fetch the flight validity for all unbooked flights as a promise
				// This is used in the checkFlightsValidity, but we can pre-fetch it here to speed things up
				state.tripPromises.flightsValidityPromise = tripService.checkFlightsValidity(tripID, unbookedFlightIDs)
			}
		} catch (error) {
			Vue.prototype.$rollbar.error(error);
			console.error(error);
		}
		state.tripPromises.tripTravellersPromise = travellerService.fetchTripTravellers(tripID);
		state.tripPromises.userTravellersPromise = travellerService.fetchUserTravellers();
	},


	handleError({ commit }, { error }) {
		Vue.prototype.$rollbar.error(error);
		console.error(error);
		let message = "Something went wrong. Please try refresh the page."
		commit('SET_LOADING', false);
		commit('SET_TRIP_ID', false); // Ensure it will re-attempt to fetch the data if loaded again.
		commit('SET_TRIP_ITEM_ID', false);
		commit('SET_ERROR', message);
	},
}
  
export const trip = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};

