<template>
<div class="menu-wrapper track-burger-menu">
	<div class="menu-burger" v-on:click="toggleMenu()">
		<img src="@/assets/menu/burger-menu.png"/>
	</div>
	<div class="menu-slideout" :class="{'open' : isMenuActive}">
	<div class="back-button" v-on:click="toggleMenu()">
		<img src="@/assets/menu/arrow-left.png"/>
	</div>
	<div class="menu-panel">
		<div class="logged-in-panel" v-if="isLoggedIn && account.userProfile !== null"> <!-- If the user is logged in -->
			<ProfileHeader
				style="cursor: auto !important;"
				:firstName = account.userProfile.paxFirstName
				:lastName = account.userProfile.paxLastName
				:country = account.userProfile.paxCountryOfIssue.name
				:coverImage = account.userProfile.coverImage
				:profileImage = account.userProfile.profileImage

				:canEdit = false

				@coverImage="(v) => { coverImage = v }"
				@profileImage="(v) => { profileImage = v }"

			/>
		</div>
		<div v-else-if="isLoggedIn && account.userProfile == null">
			<div class="login-panel loading">
				<Loader :loading="isLoading" type="white"/>
			</div>
		</div>
		<div v-else class="login-panel">
			<img src="@/assets/auth/user-white.png" alt="profile pic" class="profile-pic"/>
			<div class="login-links heading6">
				<router-link :to="{ name: 'Login' }" @click.native="toggleMenu()">Log in</router-link> / 
				<router-link :to="{ name: 'Register' }" @click.native="toggleMenu()">Sign up</router-link>
			</div>
		</div>
		<ul class="menu-items" v-if="isLoggedIn">
			<li class="level1" @click="toggleSubmenu($event)">
				<div class="row">
					<img class="icon" style="width: 12px" src="@/assets/menu/profile-icon.png" alt="Trips Icon">
					Profile
				</div>
				<ul class="sub-menu">
					<!-- <router-link :to="{ name: 'Profile' }">
						<li class="level2">
							<div class="row"> -->
								<!-- <img class="icon" style="width: 13px" src="@/assets/menu/profile-icon.png" alt="Profile Icon"> -->
								<!-- Profile
							</div>
						</li>
					</router-link> -->
					<router-link :to="{ name: 'Profile Details', query: { redirect: this.$route.path } }" @click.native="toggleMenu()">
						<li class="level2">
							<div class="row">
								<!-- <img class="icon" style="width: 13px" src="@/assets/menu/profile-icon.png" alt="Profile Icon"> -->
								Personal Information
							</div>
						</li>
					</router-link>
					<router-link :to="{ name: 'Friends', query: { redirect: this.$route.path } }" @click.native="toggleMenu()">
						<li class="level2">
							<div class="row">
								<!-- <img class="icon" style="width: 13px" src="@/assets/menu/profile-icon.png" alt="Profile Icon"> -->
								Friends
							</div>
						</li>
					</router-link>
				</ul>
			</li>
			<li class="level1" @click="toggleSubmenu($event)">
				<div class="row">
					<img class="icon" style="width: 12px" src="@/assets/menu/trips-icon.png" alt="Trips Icon">
					My Trips
				</div>
				<ul class="sub-menu">
					<div class="trip-categories">
						<!-- Icons/buttons here for changing section -->
						<router-link :to="{ name: 'My Trips', query: { redirect: this.$route.path } }" @click.native="toggleMenu()" class="btn past-btn" >
							<img class="inactive" src="@/assets/trips/past.png" />
							<img class="active" src="@/assets/trips/past-blue.png" />
							<div class="btns">
								Past
							</div>
						</router-link>
						<router-link :to="{ name: 'My Trips', query: { redirect: this.$route.path } }" @click.native="toggleMenu()" class="btn past-btn" >
							<img class="inactive" src="@/assets/trips/booked.png" />
							<img class="active" src="@/assets/trips/booked-blue.png" />
							<div class="btns">
								Booked
							</div>
						</router-link>
						<router-link :to="{ name: 'My Trips', query: { redirect: this.$route.path } }" @click.native="toggleMenu()" class="btn past-btn" >
							<img class="inactive" src="@/assets/trips/saved.png" />
							<img class="active" src="@/assets/trips/saved-blue.png" />
							<div class="btns">
								Saved
							</div>
						</router-link>
						<div class="btn create-btn" @click="createTrip()">
							<img class="plus" src="@/assets/trips/plus.png" />
							<div class="btns">
								Create
							</div>
						</div>
					</div>
				</ul>
			</li>
			<router-link :to="{ name: 'My Trips', query: { redirect: this.$route.path } }" @click.native="toggleMenu()">
				<li class="level1">
					<div class="row">
						<img class="icon" style="width: 13px" src="@/assets/menu/bookings-icon.png" alt="Bookings Icon">
						My Bookings
					</div>
				</li>
			</router-link>
			<li class="level1" @click="toggleSubmenu($event)">
				<div class="row">
					<img class="icon" src="@/assets/menu/settings-icon.png" alt="Settings Icon">
					Settings
				</div>
				<ul class="sub-menu">
					<router-link :to="{ name: 'Reset Password', query: { redirect: this.$route.path } }" @click.native="toggleMenu()">
						<li class="level2">
							<div class="row">Reset Password</div>
						</li>
					</router-link>
				</ul>
			</li>
			<router-link :to="{ name: 'Mission', query: { redirect: this.$route.path } }" @click.native="toggleMenu()">
				<li class="level1">
					<div class="row">
						<img class="icon" src="@/assets/menu/our-mission-icon.png" alt="Our Mission Icon">
						Our Mission
					</div>
				</li>
			</router-link>
			<router-link :to="{ name: 'Community Stories', query: { redirect: this.$route.path } }" @click.native="toggleMenu()">
				<li class="level1">
					<div class="row">
						<img class="icon" src="@/assets/menu/community-stories-icon.png" alt="Community Stories Icon">
						Community Stories
					</div>
				</li>
			</router-link>
			<router-link :to="{ name: 'Login', query: { redirect: this.$route.path } }" @click.native="toggleMenu()">
				<li class="level1">
					<div class="row">
						<img class="icon" src="@/assets/menu/logout-icon.png" alt="Community Stories Icon">
						Logout
					</div>
				</li>
			</router-link>
		</ul>
		<ul class="menu-items" v-else>
			<router-link :to="{ name: 'Mission', query: { redirect: this.$route.path } }" @click.native="toggleMenu()">
				<li class="level1">
					<div class="row">
						<img class="icon" src="@/assets/menu/our-mission-icon.png" alt="Our Mission Icon">
						Our Mission
					</div>
				</li>
			</router-link>
			<router-link :to="{ name: 'Community Stories', query: { redirect: this.$route.path } }" @click.native="toggleMenu()">
				<li class="level1">
					<div class="row">
						<img class="icon" src="@/assets/menu/community-stories-icon.png" alt="Community Stories Icon">
						Community Stories
					</div>
				</li>
			</router-link>
			
			<div class="select-search">
				<SearchTypeSelect />
			</div>
		</ul>
	</div>
	</div>
	<div class="background-overlay" v-on:click="toggleMenu()" :class="{'open' : isMenuActive}"></div>
</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import SearchTypeSelect from "@/components/SearchTypeSelect.vue";
import { tripService } from '@/services/tripService.js';
import router from '@/router';
import ProfileHeader from '@/components/profile/ProfileHeader.vue';
import { userService } from '@/services/userService.js'
import Loader from '@/components/Loader.vue';

export default {
	name: 'Menu',
		data() {
			return {
				isMenuActive: false,
				createTripLoading: false
			}
		},
		components: {
			SearchTypeSelect,
			ProfileHeader,
			Loader,
		},
		computed: {
			...mapState({
				account: state => state.account,
			}),
			...mapGetters({
				isLoggedIn: 'account/isLoggedIn',
			})
		},
		methods: {
			...mapActions({
				fetchUserProfile: 'account/fetchUserProfile',
			}),
			toggleMenu() {
				this.isMenuActive = !this.isMenuActive;
			},
			toggleSubmenu(event) { // TODO: classlist stuff not needed in vue. Should be done with vue variables and :class attribute
				let submenu = event.target.parentNode.querySelector('.sub-menu');
				if(submenu) {
					if(submenu.classList.contains('active')) {
						submenu.classList.remove('active');
					} else {
						submenu.classList.add('active');
					}
				}
			},
			createTrip() {
				if(!this.createTripLoading) { // Prevent spam clicks
					this.createTripLoading = true;
					tripService.createTrip()
						.then(
							response => {
								var trip_id = response.data.trip_id;
								if(trip_id) {
									router.push('/trip/' + trip_id);
								}
								this.createTripLoading = false;
							},
							error => {
								this.error(error);
								this.createTripLoading = false;
							}
						);
				}
			},
		},
		async created() {
			console.log(this.account);
			if(this.isLoggedIn && this.account.userProfile == null) {
				// // Fetch user details
				this.isLoading = true;
				// Load existing passenger details if they aren't already in state.
				this.fetchUserProfile()
					.then(response => {
						this.isLoading = false;
					}, error => {
						this.error = error;
						this.isLoading = false;
					});
			}
		}
	}
</script>

<style scoped>
	.menu-wrapper .menu-burger {
		width: 24px;
		height: 24px;
		position: absolute;
		right: 24px;
		top: 30px;
		z-index: 2;
	}
	.menu-wrapper .menu-burger img {
		width: 100%;
	}
	.menu-wrapper .menu-panel .login-panel {
		width: 100%;
		height: 152px;
		background-color: #118AB2;
		text-align: center;
	}
	.menu-wrapper .menu-panel .login-panel.loading {
		color: white;
	}
	.menu-wrapper .menu-panel .logged-in-panel  {
		background-color: unset;
		margin-bottom: 17px;
	}
	.logged-in-panel .user-header {
		padding: 47px 27px 0px;
	}
	.login-panel .profile-pic {
		width: 47px;
		margin-top: 37px;
	}
	.login-panel .login-links,
	.login-panel .login-links * {
		color: white;
		text-decoration: none;
		padding-top: 17px;
	}
	.menu-items .select-search {
		margin-top: 30px;
	}
</style>