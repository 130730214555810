<template>
	<div>
		<div class="profile-header" :class="canEdit ? 'canEdit' : ''">
			<label :for="canEdit ? 'image-select' : ''">
				<div class="cover-image" :style="[ this.headerImageDisplay ? {'background-image': `url(${this.headerImageDisplay})`, 'background-size': 'cover' } : {}]">
					<img v-if="canEdit" src="@/assets/profile/select-image.png" alt="Select image" class="select-image"/>
					<input v-if="canEdit" type="file" id="image-select" @change="onCoverImageSelected">
				</div>
			</label>
			<label :for="canEdit ? 'profile-select' : ''">
				<div class="profile-image" :style="[ this.profileImageDisplay ? {'background-image': `url(${this.profileImageDisplay})`} : {}]">
					<div v-if="!this.profileImageDisplay" class="initial">{{ this.firstName.charAt(0) }}</div>
					<input v-if="canEdit" type="file" id="profile-select" @change="onProfileImageSelected">
				</div>
			</label>
		</div>
		<div class="name">
			<span v-if="firstName || lastName">{{ firstName }} {{ lastName }}</span>
			<span v-else-if="canEdit">{{ noNameMessage }}</span>
		</div>
		<div v-if="firstName || lastName" class="country">
			{{ country }}
		</div>
	</div>
</template>

<script>
	import { mapState, mapActions, mapGetters } from 'vuex';
	export default {
		props: {
			coverImage: {
				default: false
			},
			profileImage: {
				default: false
			},
			firstName: {
				default: ""
			},
			lastName: {
				default: ""
			},
			country: {
				default: ""
			},
			noNameMessage: {
				default: "Enter details below"
			},
		
			canEdit: {
				default: false,
			}
		},
		data() {
			return {
				unsavedCoverImageDisplay: null,
				unsavedProfileImageDisplay: null,
			
				headerIsLoading: false,
				saveHeaderError: "",
			}
		},
		computed: {
			headerImageDisplay() {
				if(this.unsavedCoverImageDisplay) {
					return this.unsavedCoverImageDisplay;
				} else if(this.coverImageSelect) {
					return this.coverImageSelect;
				}
				return false;
			},
			profileImageDisplay() {
				if(this.unsavedProfileImageDisplay) {
					return this.unsavedProfileImageDisplay;
				} else if(this.profileImageSelect) {
					return this.profileImageSelect;
				}
			},

			coverImageSelect: { // Enable bidirectional mapping to prop
				get() {
					return this.coverImage;
				},
				set(v) {
					this.$emit('coverImage', v);
				}
			},
			profileImageSelect: { // Enable bidirectional mapping to prop
				get() {
					return this.profileImage;
				},
				set(v) {
					this.$emit('profileImage', v);
				}
			}
		},
		methods: {
			...mapActions({
				alertError: "alert/error",
				alertSuccess: "alert/success",
			}),
			onCoverImageSelected(event) {
				// console.log(event);
				// this.unsavedImage = event.target.files[0];
				let unsavedImage = event.target.files.item(0);

				const reader = new FileReader
				reader.onload = e => {
					this.unsavedCoverImageDisplay = e.target.result
				}
				reader.readAsDataURL(unsavedImage);

				this.coverImageSelect = unsavedImage;
			},
			onProfileImageSelected(event) {
				// console.log(event);
				// this.unsavedImage = event.target.files[0];
				let unsavedImage = event.target.files.item(0);

				const reader = new FileReader
				reader.onload = e => {
					this.unsavedProfileImageDisplay = e.target.result
				}
				reader.readAsDataURL(unsavedImage);

				this.profileImageSelect = unsavedImage;
			},
			
		},
	}
</script>

<style scoped>
	.profile-header {
		position: relative;
		padding-bottom: 45px;
	}
	.profile-header.canEdit label {
		cursor: pointer;
	}
	.profile-header input {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}

	.cover-image {
		height: 160px;
		position: relative;
		background-size: 103px;
		background-repeat: no-repeat;
		background-position: center;
		background-color: #F9F9F9;
		background-image: url('../../assets/profile/purpose-logo.png');
		box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
	}
	.cover-image .select-image {
		width: 25px;
		position: absolute;
		bottom: 7px; right: 9px;
	}


	.profile-image {
		width: 80px;
		height: 80px;
		background-size: cover;
		position: relative;
		background-position: center;
		border: 2px solid white;
		border-radius: 80px;
		box-sizing: border-box;

		position: absolute;
		bottom: 0px;
		left: 50%;
		transform: translateX(-50%);

		background-color: #118AB2;
	}
	.profile-image .initial {
		color: white;
		font-weight: 700;
		font-size: 46px;
		text-align: center;
		line-height: 76px;
	}

	.name {
		font-weight: 700;
		font-size: 24px;
		text-align: center;
		color: black;
		margin-top: 18px;
	}
	.country {
		height: 12px;
		text-align: center;
		font-size: 10px;
		color: black;
	}
</style>