import { searchService } from '@/services/searchService.js'
import helpers from '@/helpers/helpers.js';
import router from '@/router'
import track from '@/helpers/track.js'
import axios from 'axios'; // Used for passing cancelTokens to requests
import { _ } from 'core-js';

const state = {
	isMapped: false, // Marker that searchValues has/haven't been set by user - for query params syncing
	cancelToken: false, // If set, this can be used to cancel the request that set it. Used to prevent duplicate searches overwriting each other.
	searchValues: {
		tripType: "return",
		startLocation: "",
		endLocation: "",
		cabinClass: { label: 'Economy', code: 'ECONOMY' },
		dates: new Date(),
		qty: {
			adults: 1,
			children: 0,
			infants: 0,
		},
		directOnly: false,
	},
	searchOptions: {
		cabinClassOptions: [
			{ label: "Economy", code: "ECONOMY" },
			{ label: "Premium Economy", code: "PREMIUM-ECONOMY" },
			{ label: "Business Class", code: "BUSINESS" },
			{ label: "First Class", code: "FIRST" },
		],
	},
	
	searchResults: [],
	filteredSearchResults: [],

	searchResultsErrors: null,
	isLoading: false,
	shouldSearch: true,

	// Filter options are a get function that determines the options based on the search results.
	searchFilters: { // Null = no filter applied (all options selected on frontend)
		priceRange: { low: false, high: false }, // min and max price
		stops: {}, // { 0: true, 1: false, 2: true } of selected values
		airlines: {}, // { "BA": true, "EZY": false } of selected values
		maxDuration: false,
		outboundDepartureRange: { low: 0, high: 86340 }, // time of day in seconds
		returnDepartureRange: { low: 0, high: 86340 }, // time of day in seconds
		airports: null, // Array ["LGW", "LTN"]
		matchOrigin: false, // Depart and return from the same 
	},
	selectedSort: 'best',
}

//console.log(router.currentRoute.query);

const getters = {
	formattedDates(state) {
		if(state.searchValues.dates) {
			if(state.searchValues.dates.start) { // Is return 
				var start = helpers.formatDateISO(new Date(state.searchValues.dates.start))
					if(state.searchValues.dates.end != "" && state.searchValues.dates.end != null) {
						var end = helpers.formatDateISO(new Date(state.searchValues.dates.end));
					} else {
						var end = "";
					}
				var date = { start: start, end: end };
			} else if(state.searchValues.dates.start == ""){
				var start = helpers.formatDateISO(new Date());
				var date = { start: start, end: "" };
			} else {
				var start = helpers.formatDateISO(new Date(state.searchValues.dates));('DD-MM-YYYY');
				var date = { start: start, end: "" };
			}
		} else {
			var date = { start: helpers.formatDateISO(new Date()), end: "" };
		}
		return date;
	},
	isReturn(state) {
		return state.searchValues.tripType == "return";
	},
	totalPax() {
		return parseInt(state.searchValues.qty.adults) + parseInt(state.searchValues.qty.children) + parseInt(state.searchValues.qty.infants);
	},
	bestResults(state) {
		var options = {
			best: { price: null, data: null },
			cheapest: { price: null, data: null },
			fastest: { price: null, data: null },
		};
		if(Array.isArray(state.filteredSearchResults)) {
			for (const [key, result] of (state.filteredSearchResults)) {
				if(options.cheapest.price == null || parseInt(options.cheapest.price) > parseInt(result.price.from)) {
					options.cheapest.price = result.price.from;
					options.cheapest.data = result;
				}
				if(options.fastest.price == null || options.fastest.data.travel_time > result.travel_time) {
					options.fastest.price = result.price.from;
					options.fastest.data = result;
				}
				if(options.best.price == null || options.best.data.purpose_score > result.purpose_score) {
					options.best.price = result.price.from;
					options.best.data = result;
				}
			}
		}
		return options;
	},
	searchFormErrors(state, getters) {
		let errors = new Map();
		let dateErrors = [];

		if(getters.formattedDates.start == null || getters.formattedDates.start == "") {
			dateErrors.push('You must select a start date');
		}
		if(getters.isReturn) {
			if(getters.formattedDates.end == null || getters.formattedDates.end == "") {
				dateErrors.push('You must select a return date');
			}
		}
		if(dateErrors.length > 0) {
			errors.set('dates', dateErrors);
		}

		let locationErrors = [];
		if(state.searchValues.startLocation == null || state.searchValues.startLocation == "") {
			locationErrors.push('You must select a start location');
		}
		if(state.searchValues.endLocation == null || state.searchValues.endLocation == "") {
			locationErrors.push('You must select a destination');
		}
		if(locationErrors.length > 0) {
			errors.set('locations', locationErrors);
		}

		let paxErrors = [];
		let totalQty = state.searchValues.qty.adults + state.searchValues.qty.children + state.searchValues.qty.infants;
		// Do we need validation to ensure 1 passenger is an adult? Or at least no infants travelling without an adult? 
		if(totalQty == 0) {
			paxErrors.push('You must have at least 1 passenger');
		}
		if(state.searchValues.qty.infants > state.searchValues.qty.adults) {
			paxErrors.push('You must have at least 1 adult per infant');
		}
		if(parseInt(state.searchValues.qty.adults) + parseInt(state.searchValues.qty.children) > 9) {
			paxErrors.push('The combined total of adults & children can not exceed 9');
		}
		if(!state.searchValues.cabinClass) {
			paxErrors.push('You must select a cabin class');
		}
		if(paxErrors.length > 0) {
			errors.set('pax', paxErrors);
		}

		return errors;
	},
	// Based on the search results, get filter options (e.g. options that are relevant to the search, so that we don't display irrelevant options).
	// This means, the default VALUES of the filters will be based on the search results, not which filters are shown.
	getFilterOptions(state) {
		let options = {
			stops: {},
			airlines: {},
			priceRange: { low: false, high: false },
			maxDuration: false,
		};

		if(Array.isArray(state.searchResults)) {
			for (const [key, result] of (state.searchResults)) {
				// Stops
				let stops = Object.keys(result.flights).length - 1; // -1 to get stops (rather than number of flights)
				let fromPrice = parseInt(result.price.from);
				if(!options.stops.hasOwnProperty(stops)) { // Doesn't exist already
					options.stops[stops] = {
						stops: stops,
						fromPrice: fromPrice,
					};
				} else if(parseInt(options.stops[stops].fromPrice) > parseInt(result.price.from)) {
					options.stops[stops].fromPrice = fromPrice; // If already exists but has higher fromPrice, update price.
				}

				// Airlines
				let airline = result.carrier; 
				let airlineFromPrice = parseInt(result.price.from);
				if(!options.airlines.hasOwnProperty(airline.code)) { // Doesn't exist already
					options.airlines[airline.code] = {
						name: airline.name.split(' ')
							.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
							.join(' '), // Capitalise each word
						code: airline.code,
						fromPrice: airlineFromPrice,
					};
				} else if(parseInt(options.airlines[airline.code].fromPrice) > parseInt(result.price.from)) {
					options.airlines[airline.code].fromPrice = airlineFromPrice; // If already exists but has higher fromPrice, update price.
				}
				// Repeat for the return options so that we include options that are only available on the return flight
				if(result.hasOwnProperty('return_options')) {
					// Loop through
					for(let index in result.return_options) {
						let returnFlight = result.return_options[index];
						if(!options.airlines.hasOwnProperty(returnFlight.carrier.code)) {
							options.airlines[returnFlight.carrier.code] = {
								name: returnFlight.carrier.name.split(' ')
									.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
									.join(' '), // Capitalise each word
								code: returnFlight.carrier.code,
								fromPrice: airlineFromPrice,
							};
						} else if(parseInt(options.airlines[returnFlight.carrier.code].fromPrice) > parseInt(result.price.from)) {
							options.airlines[returnFlight.carrier.code].fromPrice = airlineFromPrice; // If already exists but has higher fromPrice, update price.
						}
					}
				}


				// Price range
				let price = Math.ceil(result.price.from);
				if(!options.priceRange.high || (Math.ceil(options.priceRange.high) <= price)) {
					options.priceRange.high = price;
				}
				if(!options.priceRange.low || (Math.floor(options.priceRange.low) >= price)) {
					options.priceRange.low = price;
				}

				// Max Duration
				let duration = result.travel_time;
				if(!options.maxDuration || (options.maxDuration <= duration)) {
					options.maxDuration = duration;
				}

				// Departure Range
				// // This is always midnight - midnight, no need to calculate this
			}
		}
		console.log(options);
		return options;
	}
}

const mutations = {
	SET_SEARCH_VALUES(state, values) {
		state.searchValues = values;
	},
	SET_SEARCH_RESULTS(state, results) {
		state.searchResults = Object.entries(results);
	},	
	SET_FILTERED_SEARCH_RESULTS(state, results) {
		state.filteredSearchResults = results;
	},	
	SET_SEARCH_RESULTS_ERRORS(state, errors) {
		state.searchResultsErrors = errors;
	},
	SET_SEARCH_RESULTS_LOADING(state, loading) {
		state.isLoading = loading;
	},
	SET_SHOULD_SEARCH(state, value) {
		state.shouldSearch = value;
	},
	SORT_SEARCH_RESULTS_BEST(state) {
		state.filteredSearchResults.sort(
			(a, b) =>
				a[1].purpose_score > b[1].purpose_score
					? 1
					: b[1].purpose_score > a[1].purpose_score ? -1 : 0
		);
		state.selectedSort = "best";
	},
	SORT_SEARCH_RESULTS_FASTEST(state) {
		state.filteredSearchResults.sort(
			(a, b) =>
				a[1].travel_time > b[1].travel_time
					? 1
					: b[1].travel_time > a[1].travel_time ? -1 : 0
		);
		state.selectedSort = "fastest";
	},
	SORT_SEARCH_RESULTS_CHEAPEST(state) {
		state.filteredSearchResults.sort(
			(a, b) =>
				parseInt(a[1].price.from) > parseInt(b[1].price.from)
					? 1
					: b[1].price.from > a[1].price.from ? -1 : 0
		);
		state.selectedSort = "cheapest";
	},
	SWAP_LOCATIONS(state) {
		let startLocation = state.searchValues.startLocation;
		state.searchValues.startLocation = state.searchValues.endLocation;
		state.searchValues.endLocation = startLocation;
	},
	SET_QTY(state, params) {
		state.searchValues.qty.adults = params.adults;
		state.searchValues.qty.children = params.children;
		state.searchValues.qty.infants = params.infants;
	},
	SET_DESTINATION(state, destination) {
		state.searchValues.endLocation = destination;
	}
}
  
const actions = {
	setShouldSearch({ commit }, value) {
		commit('SET_SHOULD_SEARCH', value);
	},
	submitFlightSearch({ commit, getters, dispatch, state }) {
		if(state.cancelToken) { // If there is a cancel token, then use it to cancel any previous request.
			state.cancelToken.cancel('cancel');
		}
		if(!state.shouldSearch) {
			dispatch('updateFilteredResults'); // Always do this regardless
			return false; // Don't conduct the search
		}
		let data = {
			trip_type: state.searchValues.tripType,
			start_location: {
				code: state.searchValues.startLocation.code,
				type: state.searchValues.startLocation.location_type,
			},
			end_location: {
				code: state.searchValues.endLocation.code,
				type: state.searchValues.endLocation.location_type,
			},
			cabin_class: state.searchValues.cabinClass.code,
			dates: {
				outbound: getters.formattedDates.start,
				return: getters.formattedDates.end,
			},
			times: {
				outbound: "",
				return: "",
			},
			qty: {
				adults: state.searchValues.qty.adults,
				children: state.searchValues.qty.children,
				infants: state.searchValues.qty.infants,
			},
			directOnly: state.searchValues.directOnly,
			currency: "GBP"
		};

		commit('SET_SEARCH_RESULTS_ERRORS', null);
		commit('SET_SEARCH_RESULTS_LOADING', true);

		const CancelToken = axios.CancelToken;
		state.cancelToken = CancelToken.source(); // Set the cancel token for this request into state, so we can cancel it later if needed.

		try {
			track.event('search', {
				searchType: 'flight',
				tripType: data.trip_type,
				startLocation: data.start_location.code,
				endLocation: data.end_location.code,
				cabinClass: data.cabin_class,
				outboundDate: data.dates.outbound,
				returnDate: data.dates.return,
				travellerQuantity: data.qty.adults + data.qty.children + data.qty.infants,
				directOnly: data.directOnly,
			});
		} catch(e) {
			console.error(e);
			this.$rollbar.error("Tracking: " + e);
		}
		
		searchService.submitFlightSearch(data, state.cancelToken.token)
			.then(response => {
				if(response) { // If no response, probably cancelled by user. Don't do anything with results, loading symbols etc
					if(response.data.success){
						let result = response.data.data;
						
						commit('SET_SEARCH_RESULTS', result);		
						commit('SET_SEARCH_RESULTS_LOADING', false);
						dispatch('updateFilteredResults');

						dispatch('sortOptions', state.selectedSort);
					} else {
						commit('SET_SEARCH_RESULTS', {});		
						commit('SET_SEARCH_RESULTS_ERRORS', "Something went wrong.");
						commit('SET_SEARCH_RESULTS_LOADING', false);
						dispatch('updateFilteredResults');
					}
					commit('SET_SHOULD_SEARCH', false);
				}
			},
			error => {
				commit('SET_SEARCH_RESULTS', {});		
				commit('SET_SEARCH_RESULTS_ERRORS', error);
				commit('SET_SEARCH_RESULTS_LOADING', false);
				dispatch('updateFilteredResults');
				commit('SET_SHOULD_SEARCH', false);
			});
	},
	// Applies the filters to the results and stores them in filteredResults
	updateFilteredResults({ commit, getters }) {
		// // For "Stops" filter - New options based on most recent search need to be set to true by default.
		for(let index in getters.getFilterOptions.stops) {
			let option = getters.getFilterOptions.stops[index];
			if(state.searchFilters.stops[option.stops] == undefined) { // ONLY IF the filter option is new, default it to true.
				state.searchFilters.stops[option.stops] = true;
			}
		}

		// // For "Airlines" filter - New options based on most recent search need to be set to true by default.
		if(state.searchFilters.airlines == null) {
			state.searchFilters.airlines = {};
		}

		for(let index in getters.getFilterOptions.airlines) {
			let option = getters.getFilterOptions.airlines[index];
			if(state.searchFilters.airlines[option.code] == undefined) { // ONLY IF the filter option is new, default it to true.
				state.searchFilters.airlines[option.code] = true;
			}
		}

		// Filter the searchResults based on filters
		let searchResultsClone = JSON.parse(JSON.stringify(state.searchResults)); // Clone here because we change data inside the filter. Re-filter will then use original data again.
		let filteredResults = searchResultsClone.filter((element) => {
			// Match Origin
			let matchOriginIncluded = true;
			if(state.searchFilters.matchOrigin && element[1].hasOwnProperty('return_options')) {
				let origin = element[1].origin;
				let destination = Object.values(element[1].return_options)[0].destination; // Default to first one
				// Check each return to filter out specific return options
				for(let index in element[1].return_options) {
					let option = element[1].return_options[index];
					destination = option.destination;
					if(destination != origin) {
						delete element[1].return_options[index];// Remove just that element from return options
					}
				}
				if(Object.keys(element[1].return_options).length == 0) {
					matchOriginIncluded = false; // If no more return options, filter out flight entirely
				}
			}

			// Stops
			let changes = Object.keys(element[1].flights).length - 1;
			let stopsIncluded = false; // This remains false if no stops filter option is hit
			for(let index in state.searchFilters.stops) {
				if(index == changes && state.searchFilters.stops[index] == true) {
					stopsIncluded = true;
				}
			}

			// Airlines
			let airline = element[1].carrier;
			let airlineIncluded = true; // This remains false if no stops filter option is hit
			// If the outbound carrier is not a selected airline, remove it from the search results
			if(!state.searchFilters.airlines[airline.code]) {
				airlineIncluded = false;
			} else if(element[1].hasOwnProperty('return_options')) { // If there are return options
				// Loop through the return options
				for(let index in element[1].return_options) {
					let option = element[1].return_options[index];
					// If the return carrier is not a selected airline, remove it from the return options
					if(!state.searchFilters.airlines[option.carrier.code]) {
						delete element[1].return_options[index]; // Return option doesn't fit the filter, remove just that element
					}
				}
				// If return was searched for
				if(state.searchValues.tripType == "return") {
					// If there are no more return options, remove the flight from the search results				
					if(Object.keys(element[1].return_options).length == 0) {
						airlineIncluded = false; // If no more return options, filter out flight entirely
					}
				}
			}	
			

			// for(let index in state.searchFilters.airlines) {
			// 	if(index == airline.code && state.searchFilters.airlines[index] == true) {
			// 		airlineIncluded = false;
			// 	}
			// }

			// Price Range
			let priceIncluded = false;
			let price = Math.ceil(element[1].price.from);
			if(!state.searchFilters.priceRange.low && !state.searchFilters.priceRange.high) { // Not set by the user, include it
				priceIncluded = true;
			} else if(price > Math.floor(state.searchFilters.priceRange.low) && price < Math.ceil(state.searchFilters.priceRange.high)) {
				priceIncluded = true; 
			}

			// maxDuration
			let durationIncluded = false;
			if(!state.searchFilters.maxDuration) { // Not set by user
				durationIncluded = true;
			} else if(element[1].travel_time < state.searchFilters.maxDuration) { // Set and fits filter
				durationIncluded = true;
			}

			// Departure Range
			let timesIncluded = true; // Start on true and make false if any flight doesn't fit
			let outboundFlight = element[1]; // First outbound flight
		
			// // Outbound Flight
			let departureDate = new Date(outboundFlight.departure_display); // The departure (including time of day)
			let departureStartOfDayDate = new Date(departureDate.getFullYear(), departureDate.getMonth(), departureDate.getDate()); // This removes the time of day from the date
			let timeInSeconds = (departureDate.getTime() - departureStartOfDayDate.getTime()) / 1000; // The timestamp of the time into the day
			if(timeInSeconds < state.searchFilters.outboundDepartureRange.low || timeInSeconds > state.searchFilters.outboundDepartureRange.high) {
				timesIncluded = false; // Outbound flight doesn't fit the filter
			} else if(state.searchValues.tripType == "return" && element[1].hasOwnProperty('return_options')) {
				// Check every return option
				for(let index in element[1].return_options) {
					let option = element[1].return_options[index];
					
					departureDate = new Date(option.departure_display); // The departure (including time of day)
					departureStartOfDayDate = new Date(departureDate.getFullYear(), departureDate.getMonth(), departureDate.getDate()); // This removes the time of day from the date
					timeInSeconds = (departureDate.getTime() - departureStartOfDayDate.getTime()) / 1000; // The timestamp of the time into the day
					if(timeInSeconds < state.searchFilters.returnDepartureRange.low || timeInSeconds > state.searchFilters.returnDepartureRange.high) {
						delete element[1].return_options[index]; // Return option doesn't fit the filter, remove just that element
					}
				}
				if(Object.keys(element[1].return_options).length == 0) {
					matchOriginIncluded = false; // If no more return options, filter out flight entirely
				}
			}

			let included = stopsIncluded && airlineIncluded && priceIncluded && durationIncluded && timesIncluded && matchOriginIncluded;

			console.log("stops: " + stopsIncluded);
			console.log("airline: " + airlineIncluded);
			console.log("price: " + priceIncluded);
			console.log("duration: " + durationIncluded);
			console.log("times: " + timesIncluded);
			console.log("matchOrigin: " + matchOriginIncluded);
			console.log("included: " + included);
			console.log("______________");
			

			return included;
		});
		commit('SET_FILTERED_SEARCH_RESULTS', filteredResults);

	},
	sortOptions({ commit }, type) {
		if (type == "best") {
			commit('SORT_SEARCH_RESULTS_BEST');
		} else if (type == "cheapest") {
			commit('SORT_SEARCH_RESULTS_CHEAPEST');
		} else if (type == "fastest") {
			commit('SORT_SEARCH_RESULTS_FASTEST');
		}
	},
	swapLocations({ commit }) {
		commit('SWAP_LOCATIONS');
	},
	getFlights({ commit }, { outboundKey, returnKey }) {
		return searchService.getFlights(outboundKey, returnKey);
	},
	setQty({ commit }, { adults, children, infants }) {
		if((adults + children + infants == 0)) {
			adults = 1; // If none, set to 1 as default.
		}
		commit("SET_QTY", { adults, children, infants });
	},
	// This should take any object that contains subproperties for the code, name, location_type etc (matching the location objects returned from the backend). As long as the code is provided, the location inputs will fetch the name etc from the backend when loaded.
	setDestination({ commit }, destination) {
		commit("SET_DESTINATION", destination);
	},
	mapQueryParams({}) {
		// console.log(router.currentRoute.query);
		// console.log(state.isMapped);
		if(state.isMapped) { 
			// console.log("Pushing state to query params")
			// Map state to query params - keep query params up to date
			// Foreach searchValues attribute, create query value 
			let query = { ...router.currentRoute.query };
			Object.keys(state.searchValues).forEach(key => {
				if(state.searchValues[key] !== undefined) {
					query[key] = encodeURIComponent(JSON.stringify(state.searchValues[key]));
				}
			})
			// Add to route
			if(JSON.stringify(query) !== JSON.stringify(router.currentRoute.query) ) { // Only replace the route if the query params have changed - prevents a duplicate navigation error
				router.replace({
					name: router.currentRoute.name,
					query: query,
				})
			}				
		} else { // This only happens on refresh (isMapped not yet true)
			// console.log("Mapping query params to state")
			// Map query params to state
			Object.keys(router.currentRoute.query).forEach(key => {
				// console.log(key);
				if(Object.keys(state.searchValues).includes(key)) { // Only if it's a searchValue query param (not unrelated)
					if(state.searchValues[key] !== undefined) {
						state.searchValues[key] = JSON.parse(decodeURIComponent(router.currentRoute.query[key]));
					}
				}
			
			})
		}
		// Set isSet to true (always) - to prevent query -> state mapping from happening again
		state.isMapped = true;
	},

	/**
	 * Prefill search values from trip item using the trip module to fetch required data
	 * @param {num} tripItemID
	*/
	prefillSearchValues({ rootGetters, commit }, { tripItemID }) {
		const searchValues = rootGetters['trip/getFlightSearchValues'](tripItemID);
		// Commit to state
		commit('SET_SEARCH_VALUES', searchValues);
	},
}
  
export const flightsSearch = {
    namespaced: true,
	state,
	getters,
    actions,
    mutations
};